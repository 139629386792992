export let LoginModal = {
  action: "create",
  rights: "viewer",
  MobileNo: "",
  email: "",
  name: "",
  BusinessName: "",
  accessToken: "NA",
  BMCID: "NA",
  PHID: "NA",
  businessmobileno: "",
  GSTNo: "",
  usageLimit: 0,
  Templates: [],
};
