import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { categoryActions } from "../../../redux/reducers/categoryReducer.js";
import style from "../ui/MessageFormBody.module.css";

function MessageFormBody() {
  const [bodyText, setBodyText] = useState("");
  const handleTextAreaChange = (event) => {
    setBodyText(event.target.value);
  };

  const [addVariable, setAddVariable] = useState(false);
  function handleAddVariable() {
    if (addVariable) {
      setFirstInputEnabled(false);
      setSecondInputEnabled(false);
      setThirdInputEnabled(false);
      setFirstInputVariableValue("");
      setSecondInputVariableValue("");
      setThirdInputVariableValue("");
      let text1 = bodyText.replace("{{1}}", "");
      let text2 = text1.replace("{{2}}", "");
      let text3 = text2.replace("{{3}}", "");
      setBodyText(text3);
    }
    setAddVariable(!addVariable);
  }

  const [firstInputVariableValue, setFirstInputVariableValue] = useState("");
  const [isFirstInputEnabled, setFirstInputEnabled] = useState(false);
  const handleFirstInputVariableChange = (event) => {
    setFirstInputVariableValue(event.target.value);
  };
  const handleFirstCheckboxChange = (event) => {
    if (event.target.checked) {
      setBodyText(bodyText + "{{1}}");
    } else {
      let text1 = bodyText.replace("{{1}}", "");
      let text2 = text1.replace("{{2}}", "");
      let text3 = text2.replace("{{3}}", "");
      setBodyText(text3);
      setSecondInputEnabled(false);
      setThirdInputEnabled(false);
      setFirstInputVariableValue("");
      setSecondInputVariableValue("");
      setThirdInputVariableValue("");
    }
    setFirstInputEnabled(!isFirstInputEnabled);
  };

  const [secondInputVariableValue, setSecondInputVariableValue] = useState("");
  const [isSecondInputEnabled, setSecondInputEnabled] = useState(false);
  const handleSecondInputVariableChange = (event) => {
    setSecondInputVariableValue(event.target.value);
  };
  const handleSecondCheckboxChange = (event) => {
    if (event.target.checked) {
      setBodyText(bodyText + "{{2}}");
    } else {
      let text = bodyText.replace("{{2}}", "");
      let text1 = text.replace("{{3}}", "");
      setBodyText(text1);
      setSecondInputVariableValue("");
      setThirdInputVariableValue("");
      setThirdInputEnabled(false);
    }
    setSecondInputEnabled(!isSecondInputEnabled);
  };

  const [thirdInputVariableValue, setThirdInputVariableValue] = useState("");
  const [isThirdInputEnabled, setThirdInputEnabled] = useState(false);
  const handleThirdInputVariableChange = (event) => {
    setThirdInputVariableValue(event.target.value);
  };
  const handleThirdCheckboxChange = (event) => {
    if (event.target.checked) {
      setBodyText(bodyText + "{{3}}");
    } else {
      setBodyText(bodyText.replace("{{3}}", ""));
      setThirdInputVariableValue("");
    }
    setThirdInputEnabled(!isThirdInputEnabled);
  };

  // Footer state
  const [footerValue, setFooterValue] = useState("");
  function handleFooterChange(event) {
    setFooterValue(event.target.value);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    if (bodyText === "") {
      console.log("Stopped!");
      dispatch(categoryActions.addBody(null));
      return;
    }
    const data = {
      value: bodyText,
      variables: [
        {
          firstVariable: isFirstInputEnabled,
          firstVariableValue: firstInputVariableValue,
        },
        {
          secondVariable: isSecondInputEnabled,
          secondVariableValue: secondInputVariableValue,
        },
        {
          thirdVariable: isThirdInputEnabled,
          thirdVariableValue: thirdInputVariableValue,
        },
      ],
      footerValue: footerValue,
    };

    dispatch(categoryActions.addBody(data));
  }, [
    bodyText,
    addVariable,
    isFirstInputEnabled,
    isSecondInputEnabled,
    isThirdInputEnabled,
    firstInputVariableValue,
    secondInputVariableValue,
    thirdInputVariableValue,
    footerValue,
  ]);

  return (
    <div className={style.container} style={{ marginBottom: "20px" }}>
      <span>
        <b>Body</b> <br />
        <span style={{ fontSize: "small", fontWeight: "500" }}>
          Enter the text for your message in the language that you've selected.
        </span>
      </span>
      <br />
      <textarea
        name="bodyText"
        value={bodyText}
        onChange={handleTextAreaChange}
        style={{
          width: "100%",
          fontSize: "14px",
          border: "1px solid #ccc",
          borderRadius: "5px",
          marginTop: "2vh",
        }}
        rows={6}
        cols={50}
        maxLength={1024}
        placeholder="Enter your message here..."
      />
      <div style={{ display: "flex", justifyContent: "end" }}>
        <span
          onClick={handleAddVariable}
          style={{ fontSize: "small", padding: "6px", cursor: "pointer" }}
        >
          {" "}
          + Add variable
        </span>
      </div>
      {addVariable ? (
        <div>
          <div className={style.dropdownContainer}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                checked={isFirstInputEnabled}
                onChange={handleFirstCheckboxChange}
              />
              <span style={{ margin: "10px" }}>{"{{1}}"}</span>
            </label>
            <input
              type="text"
              id="input"
              value={firstInputVariableValue}
              onChange={handleFirstInputVariableChange}
              disabled={!isFirstInputEnabled}
              placeholder="Enter content for {{1}}"
              style={{
                width: "91%",
                padding: "6px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                outline: "none",
              }}
            />
          </div>
          {isFirstInputEnabled ? (
            <>
              <div className={style.dropdownContainer}>
                <label
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    checked={isSecondInputEnabled}
                    onChange={handleSecondCheckboxChange}
                  />
                  <span style={{ margin: "10px" }}>{"{{2}}"}</span>
                </label>
                <input
                  type="text"
                  id="input"
                  value={secondInputVariableValue}
                  onChange={handleSecondInputVariableChange}
                  disabled={!isSecondInputEnabled}
                  placeholder="Enter content for {{2}}"
                  style={{
                    width: "91%",
                    padding: "6px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                    outline: "none",
                  }}
                />
              </div>

              {isSecondInputEnabled ? (
                <div className={style.dropdownContainer}>
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={isThirdInputEnabled}
                      onChange={handleThirdCheckboxChange}
                    />
                    <span style={{ margin: "10px" }}>{"{{3}}"}</span>
                  </label>
                  <input
                    type="text"
                    id="input"
                    value={thirdInputVariableValue}
                    onChange={handleThirdInputVariableChange}
                    disabled={!isThirdInputEnabled}
                    placeholder="Enter content for {{3}}"
                    style={{
                      width: "91%",
                      padding: "6px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                      outline: "none",
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      {addVariable &&
        ((isFirstInputEnabled && firstInputVariableValue === "") ||
          (isSecondInputEnabled && secondInputVariableValue === "") ||
          (isThirdInputEnabled && thirdInputVariableValue === "")) && (
          <span style={{ fontSize: "small", color: "red" }}>
            Add text to all selected variables!!! <br />
          </span>
        )}
      <br />
      <div>
        <span>
          <b>Footer</b> <b style={{ fontSize: "small" }}> Optional</b>
          <br />
          <span style={{ fontSize: "small", fontWeight: "500" }}>
            Add a short line of text to the bottom of your message template.If
            you add the marketing opt-out button, the asociated footer will be
            shown here by default.
          </span>
        </span>
        <br />
        <br />
        <input
          type="text"
          id="input"
          value={footerValue}
          onChange={handleFooterChange}
          placeholder="Enter footer text here..."
          style={{
            width: "99%",
            padding: "6px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            outline: "none",
          }}
          maxLength={60}
        />
      </div>
      <br />
    </div>
  );
}

export default MessageFormBody;
