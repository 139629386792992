import React from "react";
import style from "./SuperAdmin.module.css";
import { useDispatch, useSelector } from "react-redux";
import { child, ref, update } from "firebase/database";
import { db } from "../../core/firebase/Firebase.js";
import {
  superAdminActions,
  superAdminSelector,
} from "../../redux/reducers/superAdminReducer.js";

function SuperAdmin() {
  const superAdminRedux = useSelector(superAdminSelector);
  const dispatch = useDispatch();

  function updateDB(MobileNo, rights) {
    const dbref = ref(db);
    update(child(dbref, `UserProfiles/${MobileNo}`), { rights: rights })
      .then(() => {
        console.log("user rights updated in db");
        dispatch(
          superAdminActions.updateOne({ MobileNo: MobileNo, rights: rights })
        );
      })
      .catch((err) => console.log("Err :", err));
  }

  async function rightsChange(e, MobileNo, rights) {
    e.preventDefault();
    updateDB(MobileNo, rights);
  }

  return (
    <div className={style.container}>
      <div className={style.childContainer}>
        <h1>Super Admin</h1>
        <div className={style.adminPanel}>
          <table>
            <thead>
              <tr>
                <th>User</th>
                <th>Email</th>
                <th>MobileNo</th>
                <th>Rights</th>
              </tr>
            </thead>
            <tbody>
              {superAdminRedux.length > 0 &&
                superAdminRedux.map((user, index) => (
                  <tr key={index}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.MobileNo}</td>
                    <td>
                      <select
                        id="rights"
                        value={user.rights}
                        onChange={(e) =>
                          rightsChange(e, user.MobileNo, e.target.value)
                        }
                        style={{
                          padding: "8px",
                          fontSize: "16px",
                          borderRadius: "4px",
                          border: "1px solid #ccc",
                          backgroundColor: "#fff",
                          color: "#333",
                          outline: "none",
                        }}
                      >
                        <option value="super-admin">Super Admin</option>
                        <option value="admin">Admin</option>
                        <option value="manager">Manager</option>
                        <option value="viewer">Viewer</option>
                      </select>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SuperAdmin;
