import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { categoryActions } from "../../../redux/reducers/categoryReducer.js";
import style from "../ui/MessageFormHeader.module.css";
import Media from "./Media.js";

function MessageFormHeader() {
  const [selectedOption, setSelectedOption] = useState("Text");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event) => {
    event.preventDefault();
    setInputValue(event.target.value);
  };

  const [addVariable, setAddVariable] = useState(false);

  function handleAddVariable() {
    if (addVariable) {
      setInputValue(inputValue.replace("{{1}}", ""));
      setInputEnabled(false);
      setInputVariableValue("");
    }
    setAddVariable(!addVariable);
  }

  const [inputVariableValue, setInputVariableValue] = useState("");
  const [isInputEnabled, setInputEnabled] = useState(false);
  const [validation, setValidation] = useState(false);

  const handleInputVariableChange = (event) => {
    event.preventDefault();
    setInputVariableValue(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    event.preventDefault();
    if (event.target.checked) {
      setInputValue(inputValue + "{{1}}");
    } else {
      setInputValue(inputValue.replace("{{1}}", ""));
      setInputVariableValue("");
    }

    setInputEnabled(!isInputEnabled);
    if (!isInputEnabled) {
      setInputVariableValue("");
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (inputValue === "") {
      dispatch(categoryActions.addHeader(null));
      return;
    }
    if (isInputEnabled && inputVariableValue === "") {
      setValidation(true);
      dispatch(categoryActions.addHeader(null));
      return;
    }
    setValidation(false);
    const data = {
      headerType: selectedOption,
      header: inputValue,
      headervariable: {
        present: isInputEnabled,
        value: inputVariableValue,
      },
    };

    dispatch(categoryActions.addHeader(data));
  }, [
    addVariable,
    selectedOption,
    inputValue,
    isInputEnabled,
    inputVariableValue,
  ]);

  return (
    <div className={style.container}>
      <h3>Edit template</h3>
      <hr />
      <span>
        <b>Header</b> <b style={{ fontSize: "small" }}> Optional</b>
        <br />
        <span style={{ fontSize: "small", fontWeight: "500" }}>
          Add a title or choose which type of media you'll use for this header.
        </span>
      </span>
      <br />
      <div className={style.dropdownContainer}>
        <select
          id="dropdown"
          value={selectedOption}
          onChange={handleOptionChange}
          style={{
            padding: "5px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            outline: "none",
          }}
        >
          <option value="Text">Text</option>
          <option value="Media">Media</option>
        </select>
        {selectedOption.toLowerCase() === "text" && (
          <input
            type="text"
            id="input"
            placeholder="Enter message header.."
            value={inputValue}
            onChange={handleInputChange}
            style={{
              width: "100%",
              marginLeft: "2%",
              padding: "6px",
              borderRadius: "5px",
              border: "1px solid #ccc",
              outline: "none",
            }}
            maxLength={60}
          />
        )}
      </div>
      {selectedOption.toLowerCase() === "text" && (
        <div style={{ display: "flex", justifyContent: "end" }}>
          <span
            onClick={handleAddVariable}
            style={{ fontSize: "small", padding: "6px", cursor: "pointer" }}
          >
            {" "}
            + Add variable
          </span>
        </div>
      )}
      {selectedOption !== "Media" && addVariable ? (
        <>
          <div className={style.dropdownContainer}>
            <label
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="checkbox"
                checked={isInputEnabled}
                onChange={handleCheckboxChange}
              />
              <span style={{ margin: "10px" }}>{"{{1}}"}</span>
            </label>
            <input
              type="text"
              id="input"
              value={inputVariableValue}
              onChange={handleInputVariableChange}
              disabled={!isInputEnabled}
              placeholder="Enter content for {{1}}"
              style={{
                width: "100%",
                padding: "6px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                outline: "none",
              }}
            />
          </div>
          {validation && (
            <span style={{ fontSize: "small", color: "red" }}>
              Variable text field can't be empty!
            </span>
          )}
        </>
      ) : (
        <></>
      )}
      <br />
      {selectedOption === "Media" ? <Media /> : <></>}
      <br />
    </div>
  );
}

export default MessageFormHeader;
