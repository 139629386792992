import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  templateActions,
  templateSelector,
} from "../../../redux/reducers/templateReducer.js";
import style from "../ui/SendMessage.module.css";
import SendMessageBody from "./SendMessageBody.js";
import { userSelector } from "../../../redux/reducers/userReducer.js";
import {
  campaignBaseApi,
  createTemplateUrl,
} from "../../../core/apiServices/apiServices.js";
import {
  baseActions,
  baseSelector,
} from "../../../redux/reducers/baseReducer.js";

function SendMessage() {
  const [campaign, setCampaign] = useState("");
  const [template, setTemplate] = useState({});
  const [templateName, setTemplateName] = useState("");
  const [base, setBase] = useState({ baseName: "", data: "" });
  const [baseName, setBaseName] = useState("");

  const userRedux = useSelector(userSelector);
  let templateRedux = useSelector(templateSelector);
  let baseRedux = useSelector(baseSelector);

  if (templateRedux) {
    templateRedux = templateRedux.filter((ele) => ele.status === "APPROVED");
  }

  function handleTemplateSelect(e) {
    const data = templateRedux.filter((ele) => ele.name === e.target.value);
    setTemplate(data[0]);
    setTemplateName(e.target.value);
  }

  function handleBaseSelect(e) {
    const data = baseRedux.filter((ele) => ele.baseName === e.target.value);
    setBase(data[0]);
    setBaseName(e.target.value);
  }

  const dispatch = useDispatch();

  useEffect(() => {
    function filterUserTemplates(all) {
      const reduxUserTemplates = [...userRedux.Templates];

      const filteredTemplates = all.filter((item) =>
        reduxUserTemplates.includes(item.name)
      );
      dispatch(templateActions.add(filteredTemplates));
    }

    function fetchTemplates() {
      try {//rana
        fetch(`${createTemplateUrl}?BACID=${userRedux.BMCID}`,{method:'GET'})
          .then((res) => res.json())
          .then((data) => {
            dispatch(templateActions.add(data.data));
            // filterUserTemplates(data.data);
          })
          .catch((err) => console.log("Error :", err));
      } catch (err) {
        console.log("Error :", err);
      }
    }
    fetchTemplates();
    function fetchBase() {
      try {
        fetch(campaignBaseApi, {
          method: "POST",
          body: JSON.stringify({
            action: "read",
            mobileNo: userRedux.MobileNo,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            const objToArr = Object.values(data);
            dispatch(baseActions.add(objToArr));
          })
          .catch((err) => console.log("Error :", err));
      } catch (err) {
        console.log("Error :", err);
      }
    }
    fetchBase();
  }, []);

  return (
    <div className={style.container}>
      <div className={style.topContainer}>
        <div className={style.searchContainer}>
          <input
            type="text"
            value={campaign}
            placeholder="Campaign name here ..."
            onChange={(e) => setCampaign(e.target.value)}
            className={style.campaignInput}
          />
          <select
            value={templateName}
            onChange={(e) => handleTemplateSelect(e)}
            className={style.templateSelect}
          >
            {templateName === "" && (
              <option value="" disabled defaultValue>
                Select Template
              </option>
            )}
            {templateRedux &&
              templateRedux.map((e, index) => (
                <option value={e.name} key={index}>
                  {e.name}
                </option>
              ))}
          </select>
          <select
            value={baseName}
            onChange={(e) => handleBaseSelect(e)}
            className={style.templateSelect}
          >
            {baseName === "" && (
              <option value="" disabled defaultValue>
                Select Base
              </option>
            )}
            {baseRedux &&
              baseRedux.map((e, index) => (
                <option value={e.baseName} key={index}>
                  {e.baseName}
                </option>
              ))}
          </select>
        </div>
        <div className={style.createTemplateContainer}>
          <Link to="/home/create" className={style.sendButton}>
            Create Template
          </Link>
        </div>
      </div>
      <div className={style.bodyContainer}>
        <SendMessageBody
          campaign={campaign}
          template={template}
          selectedBase={base}
        />
      </div>
    </div>
  );
}

export default SendMessage;
