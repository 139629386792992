import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import style from "./Splash.module.css";

function Splash() {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/login");
    }, 3000);
  }, []);

  return (
    <div className={style.container}>
      <h1 className={style.loginHeader} style={{ fontSize: "8em" }}>
        One17
      </h1>

      <span className={style.loginHeader}>loading...</span>
    </div>
  );
}

export default Splash;
