import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../core/firebase/Firebase.js";
import style from "../ui/Login.module.css";
import { LoginModal } from "../modal/Login.modal.js";
import { userActions } from "../../../redux/reducers/userReducer.js";
import { userProfileUrl } from "../../../core/apiServices/apiServices.js";

function Login() {
  const [selectedCountryCode, setSelectedCountryCode] = useState("91");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [confirmUser, setConfirmUser] = useState(null);
  const [otp, setOtp] = useState("");
  const [goHome, setGoHome] = useState(false);
  const [validation, setValidation] = useState("");
  const [user, setUser] = useState(null);

  const dispatch = useDispatch();

  const handleCountryDropdownChange = (event) => {
    event.preventDefault();
    setSelectedCountryCode(event.target.value);
    LoginModal.MobileNo = event.target.value + phoneNumber;
  };

  const handlePhoneNumberChange = (event) => {
    event.preventDefault();
    setPhoneNumber(event.target.value);
    LoginModal.MobileNo = selectedCountryCode + event.target.value;
  };

  const [readOnly, setReadOnly] = useState(true);

  function handleEditPhoneNumber(e) {
    e.preventDefault();
    setReadOnly(!readOnly);
    setConfirmUser(null);
    setGoHome(false);
  }

  async function userInDB(dataJson) {
    try {
      fetch(userProfileUrl, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataJson),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("fetch userprofiles :", data);
          if (!data.error) {
            dispatch(userActions.add(data));
            setUser(data);
            setGoHome(true);
            // if (data.rights === "viewer") {
            //   console.log("viewer");
            //   navigate("/pending");
            //   return;
            // }
          }
        })
        .catch((err) => {
          console.log("Error :", err);
        });
    } catch (err) {
      console.log("Error :", err);
    }
  }

  async function captchVerify(e) {
    e.preventDefault();
    if (phoneNumber.length < 10 || phoneNumber.length > 10) {
      setValidation("Phone number length");
      return;
    }
    setValidation("");

    const dataJson = {
      action: "read",
      MobileNo: selectedCountryCode + phoneNumber,
    };

    await userInDB(dataJson);

    let recaptcha = new RecaptchaVerifier(auth, "recaptcha-container", {});
    const PhoneNum = "+" + selectedCountryCode + phoneNumber;
    let confirmation = await signInWithPhoneNumber(
      auth,
      PhoneNum,
      recaptcha
    ).catch((err) => console.log(("Err :", err)));

    console.log("it runs later", confirmation);

    if (!confirmation) {
      setValidation("Captcha");
      setConfirmUser(null);
      setReadOnly(true);
      return;
    }
    setValidation("");

    LoginModal.MobileNo = selectedCountryCode + phoneNumber;
    dispatch(userActions.addPhone(selectedCountryCode + phoneNumber));
    setConfirmUser(confirmation);
    setReadOnly(!readOnly);
  }

  const navigate = useNavigate();

  function handleHome(e) {
    e.preventDefault();

    if (otp.length < 6 || otp.length > 6) {
      setValidation("OTP");
      return;
    }
    setValidation("");

    confirmUser
      .confirm(otp)
      .then((result) => {
        console.log(user.rights);
        if (user.rights === "viewer") {
          navigate("/pending");
          return;
        } else if (user.rights !== "viewer") {
          dispatch(userActions.onlyAuth());
          navigate("/home");
          return;
        }
      })
      .catch((error) => {
        if (error.code === "auth/invalid-verification-code") {
          setValidation("OTP");
          console.error("Err invalid otp :", error);
        } else {
          setValidation("OTP");
          console.error("Err :", error);
        }
      });
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (otp.length < 6 || otp.length > 6) {
      setValidation("OTP");
      return;
    }
    setValidation("");

    confirmUser
      .confirm(otp)
      .then((result) => {
        navigate("/register");
      })
      .catch((error) => {
        if (error.code === "auth/invalid-verification-code") {
          setValidation("OTP");
          console.error("Err invalid otp :", error);
        } else {
          setValidation("OTP");
          console.error("Err :", error);
        }
      });
  }

  return (
    <div className={style.container}>
      <div className={style.childContainer}>
        <h1 className={style.loginHeader}>LogIn</h1>
        <br />
        <div
          style={{
            display: "flex",
            width: "60%",
            justifyContent: "space-between",
          }}
        >
          <select
            value={selectedCountryCode}
            onChange={(event) => handleCountryDropdownChange(event)}
            disabled={!readOnly}
            style={{
              width: "26%",
              padding: "6px",
              borderRadius: "5px",
              border: "1.5px solid rgb(204 204 204)",
              outline: "none",
              boxShadow: " 1px 1px 2px rgba(0, 0, 0, 0.3)",
            }}
          >
            <option value="1">+1 U.S.</option>
            <option value="44">+44 UK</option>
            <option value="91">+91 India</option>
          </select>

          <input
            type="text"
            name="phone"
            id="phone"
            maxLength={20}
            pattern="[0-9]*"
            required
            value={phoneNumber}
            onChange={(event) => handlePhoneNumberChange(event)}
            readOnly={!readOnly}
            placeholder="Enter phone number"
            style={{
              width: "65%",
              padding: "6px",
              borderRadius: "5px",
              border: "1.5px solid rgb(204 204 204)",
              outline: "none",
              boxShadow: " 1px 1px 2px rgba(0, 0, 0, 0.3)",
            }}
          />
        </div>

        <br />
        <input
          type="text"
          name="otp"
          id="otp"
          maxLength={6}
          pattern="[0-9]*"
          required
          value={otp}
          readOnly={readOnly}
          onChange={(event) => setOtp(event.target.value)}
          placeholder="Enter OTP"
          style={{
            width: "58%",
            padding: "6px",
            borderRadius: "5px",
            border: "1.5px solid rgb(204 204 204)",
            outline: "none",
            textAlign: "center",
            boxShadow: " 1px 1px 2px rgba(0, 0, 0, 0.3)",
          }}
        />
        {validation !== "" && (
          <span
            style={{ color: "orange", fontSize: "small", fontWeight: "bold" }}
          >
            <br />
            {validation} is not correct!
          </span>
        )}
        <br />
        {readOnly && (
          <>
            <button
              className={style.nextButton}
              onClick={(e) => captchVerify(e)}
            >
              Send OTP
            </button>
          </>
        )}
        {!readOnly && goHome && (
          <Link to="#" replace onClick={(e) => handleHome(e)}>
            <button className={style.nextButton}>Home</button>
          </Link>
        )}
        {!readOnly && !goHome && (
          <Link to="#" replace onClick={(e) => handleSubmit(e)}>
            <button className={style.nextButton}>Register</button>
          </Link>
        )}
        <br />
        {!readOnly && (
          <Link
            to="#"
            style={{ color: "grey", fontSize: "small" }}
            onClick={(e) => handleEditPhoneNumber(e)}
          >
            Edit phone number or Resend OTP
          </Link>
        )}
        <br />
        {!confirmUser && (
          <div
            id="recaptcha-container"
            style={{ marginTop: "1vh" }}
            // className={style.recaptcha}
          ></div>
        )}
      </div>
    </div>
  );
}

export default Login;
