export const campaignMasterModal = {
  campaignName: "",
  templateName: "",
  totalContacts: "10",
  validMes: 0,
  errorMes: 0,
  delivered: 0,
  read: 0,
  sent: 0,
  date: new Date().getDate(),
  time: new Date().getTime(),
};
