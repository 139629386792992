import React, { useState } from "react";
// import { useSelector } from "react-redux";
// import { templateSelector } from "../../../redux/reducers/templateReducer.js";
import "../ui/MessageTemplatesTable.css";

function MessageTemplateTable({ template }) {
  const [selectedRow, setSelectedRow] = useState(null);

  // const templateRedux = useSelector(templateSelector);

  const handleClick = (id) => {
    setSelectedRow(id);
  };

  return (
    <div className="msgTempTableContainer">
      <div className="msgTempTableChildContainer">
        <table>
          <thead>
            <tr>
              <th>Template Name</th>
              <th>Template Type</th>
              <th>Language</th>
              <th>Status</th>
              {/* <th>Message Delivered</th>
              <th>Message read rate</th>
              <th>Top block reason</th>
              <th>Last updated</th> */}
            </tr>
          </thead>
          <tbody>
            {template &&
              template.map((val, key) => {
                return (
                  <tr
                    key={key}
                    onMouseEnter={() => handleClick(key)}
                    onMouseLeave={() => setSelectedRow(null)}
                    style={{
                      cursor: "pointer",
                      backgroundColor:
                        selectedRow === key ? "lightgrey" : "white",
                      borderColor: selectedRow === key ? "lighgrey" : "white",
                    }}
                  >
                    <td>{val.name}</td>
                    <td>{val.category}</td>
                    <td>{val.language}</td>
                    <td>{val.status}</td>
                    {/* <td>{val.delivered}</td>
                  <td>{val.readRate}</td>
                  <td>{val.topBlockReason}</td>
                  <td>{val.LastUpdated}</td> */}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MessageTemplateTable;
