import React from "react";
import style from "../ui/Template.module.css";
// import TopPanel from "./TopPanel.js";
// import LeftPanel from "./LeftPanel.js";
import MessageForm from "./MessageForm.js";

function Template({ categoryForm }) {
  console.log("Body ", categoryForm);
  return (
    <div className={style.container}>
      <div className={style.childContainer}>
        <MessageForm />
      </div>
    </div>
  );
}

export default Template;
