import React, { useEffect, useState } from "react";

function QuickReplyType({ name, handleQuickReply }) {
  const [type, setType] = useState("QUICK_REPLY");
  const [value, setValue] = useState("");

  const handleDropdownChange = (event) => {
    setType(event.target.value);
  };

  const [isReadOnly, setReadOnly] = useState(true);

  const handleCheckboxChange = () => {
    // handleQuickReply(name, null);
    setValue("");
    setReadOnly(!isReadOnly); // Toggle readOnly state
  };

  useEffect(() => {
    if (value === "") {
      handleQuickReply(name, null);
      return;
    }
    handleQuickReply(name, { type: type, value: value });
  }, [type, value, isReadOnly]);

  return (
    <div
      style={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "10px",
        width: "98%",
      }}
    >
      <div style={{ width: "6%" }}>
        <input type="checkbox" onChange={handleCheckboxChange} />
      </div>
      {type === "QUICK_REPLY" && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "96%",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", width: "18%" }}
          >
            <label for="type" style={{ fontSize: "small" }}>
              Type
            </label>
            <select
              name="type"
              id="type"
              value={type}
              onChange={handleDropdownChange}
              disabled={isReadOnly}
              style={{
                width: "100%",
                padding: "6px",
                borderRadius: "5px",
                border: "1px solid rgb(204, 204, 204)",
                outline: "none",
              }}
            >
              <option value="QUICK_REPLY" selected>
                Custom
              </option>
              <option value="Marketing Opt-out" disabled>
                Marketing Opt-out (coming soon )
              </option>
            </select>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", width: "79%" }}
          >
            <label for="buttontext" style={{ fontSize: "small" }}>
              Button text
            </label>
            <input
              type="text"
              name="buttontext"
              value={value}
              onChange={(event) => setValue(event.target.value)}
              readOnly={isReadOnly}
              maxLength={25}
              style={{
                width: "100%",
                padding: "6px",
                borderRadius: "5px",
                border: "1px solid rgb(204, 204, 204)",
                outline: "none",
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default QuickReplyType;
