import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const templateSlice = createSlice({
  name: "templatedata",
  initialState: initialState,
  reducers: {
    add: (state, action) => {
      state.data = action.payload;
    },
    delete: (state, action) => {
      console.log("reducer", action.payload.ind);
      state.data = state.data.filter((c, i) => i !== action.payload.ind);
    },
    empty: (state, action) => {
      state.data = [];
    },
  },
});

export const templateReducer = templateSlice.reducer;

export const templateActions = templateSlice.actions;

export const templateSelector = (state) => state.templateReducer.data;
