import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate("/");
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="not-found page">
        <h3>Page not found.</h3>
        <img
          src="https://cdn-icons-png.flaticon.com/512/2748/2748558.png"
          alt="not found"
        />

        <button
          onClick={handleBack}
          style={{
            backgroundColor: "orange",
            color: "white",
            padding: "10px 20px",
            fontWeight: "bold",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
          }}
        >
          Go back safely
        </button>
      </div>
    </div>
  );
};

export default NotFound;
