import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "./SearchBar.js";
import { templateActions } from "../../../redux/reducers/templateReducer.js";
import { createTemplateUrl } from "../../../core/apiServices/apiServices.js";
import "../ui/MessageTemplates.css";
import { userSelector } from "../../../redux/reducers/userReducer.js";

function MessageTemplates() {
  const [backupTemplates, setBackupTemplates] = useState([]);
  const [templates, setTemplates] = useState([]);
  const userRedux = useSelector(userSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    function filterUserTemplates(all) {
      const reduxUserTemplates = [...userRedux.Templates];

      const filteredTemplates = all.filter((item) =>
        reduxUserTemplates.includes(item.name)
      );

      setTemplates(filteredTemplates);
      setBackupTemplates(filteredTemplates);
      dispatch(templateActions.add(filteredTemplates));
    }

    function fetchTemplates() {
      try {//rana
        console.log(userRedux.BMCID);
        fetch(`${createTemplateUrl}?BACID=${userRedux.BMCID}`,{method:'GET'})
          .then((res) => res.json())
          .then((data) => {
            console.log(data.data);
            dispatch(templateActions.add(data.data));
            setTemplates(data.data);
            setBackupTemplates(data.data);
            // filterUserTemplates(data.data);
          })
          .catch((err) => console.log("Error :", err));
      } catch (err) {
        console.log("Error :", err);
      }
    }
    fetchTemplates();
  }, []);

  function handleSearchTemplates(val) {
    if (val === "all") {
      setTemplates(backupTemplates);
    } else {
      setTemplates(val);
    }
  }

  return (
    <div className="tempContainer">
      <SearchBar
        templates={templates}
        handleSearchTemplates={handleSearchTemplates}
      />
    </div>
  );
}

export default MessageTemplates;
