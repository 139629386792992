// nimport React from "react";
import style from "../ui/Dashboard.module.css";
import { useSelector } from "react-redux";
import { userSelector } from "../../../redux/reducers/userReducer.js";

function Dashboard() {
  const userRedux = useSelector(userSelector);
  console.log(userRedux);

  return (
    <div className={style.container}>
      <div className={style.childContainer}>
        <h1>Dashboard</h1>
        <div className={style.gcContainer}>
          <div className={style.record}>
            <h3>
              <b>Messages Left : {userRedux.usageLimit}</b>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
