import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import style from "../ui/Registration.module.css";
import { LoginModal } from "../modal/Login.modal.js";
import { userActions } from "../../../redux/reducers/userReducer.js";
import { userProfileUrl } from "../../../core/apiServices/apiServices.js";

function Registration() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessMobile, setBusinessMobile] = useState("");
  const [gst, setGst] = useState("");
  const [validation, setValidation] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function isValidEmail(isEmail) {
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(isEmail);
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (name === "") {
      setValidation("Name");
      return;
    } else if (email === "") {
      setValidation("Email");
      return;
    } else if (!isValidEmail(email)) {
      setValidation("Invalid email");
      return;
    } else if (businessName === "") {
      setValidation("Business name");
      return;
    }
    setValidation("");

    function setUser() {
      try {
        fetch(userProfileUrl, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(LoginModal),
        })
          .then((res) => res.json())
          .then((data) => {
            if (!data.error) {
              LoginModal.rights = data.rights;
              dispatch(userActions.addRegister(data));
              if (data.rights === "viewer") {
                navigate("/pending");
                return;
              } else if (data.rights !== "viewer") {
                navigate("/home");
                return;
              }
            } else {
              setValidation("Error while registering ,try again!");
            }
          })
          .catch((err) => {
            setValidation("Error while registering ");
            console.log("Error :", err);
          });
      } catch (err) {
        setValidation("Error while registering ");
        console.log("Error :", err);
      }
    }
    setUser();
  }

  return (
    <div className={style.container}>
      <div className={style.childContainer}>
        <h1 className={style.loginHeader}>Register</h1>
        <br />
        <input
          type="text"
          name="name"
          id="name"
          required
          value={name}
          onChange={(event) => {
            LoginModal.name = event.target.value;
            setName(event.target.value);
          }}
          placeholder="Enter name..."
          style={{
            width: "58%",
            padding: "6px",
            borderRadius: "5px",
            border: "1px solid rgb(204 204 204)",
            outline: "none",
            boxShadow: " 1px 1px 2px rgba(0, 0, 0, 0.3)",
          }}
        />
        <br />
        <input
          type="text"
          name="email"
          id="email"
          value={email}
          onChange={(event) => {
            LoginModal.email = event.target.value;
            setEmail(event.target.value);
          }}
          placeholder="Enter email..."
          style={{
            width: "58%",
            padding: "6px",
            borderRadius: "5px",
            border: "1px solid rgb(204 204 204)",
            outline: "none",
            boxShadow: " 1px 1px 2px rgba(0, 0, 0, 0.3)",
          }}
        />

        <br />
        <input
          type="text"
          name="businessName"
          id="businessName"
          value={businessName}
          onChange={(event) => {
            LoginModal.BusinessName = event.target.value;
            setBusinessName(event.target.value);
          }}
          placeholder="Enter business name..."
          style={{
            width: "58%",
            padding: "6px",
            borderRadius: "5px",
            border: "1px solid rgb(204 204 204)",
            outline: "none",
            boxShadow: " 1px 1px 2px rgba(0, 0, 0, 0.3)",
          }}
        />
        <br />
        <input
          type="text"
          name="businessMobile"
          id="businessMobile"
          value={businessMobile}
          onChange={(event) => {
            LoginModal.businessmobileno = event.target.value;
            setBusinessMobile(event.target.value);
          }}
          placeholder="Enter business mobile... (optional)"
          style={{
            width: "58%",
            padding: "6px",
            borderRadius: "5px",
            border: "1px solid rgb(204 204 204)",
            outline: "none",
            boxShadow: " 1px 1px 2px rgba(0, 0, 0, 0.3)",
          }}
        />
        <br />
        <input
          type="text"
          name="gst"
          id="gst"
          value={gst}
          onChange={(event) => {
            LoginModal.GSTNo = event.target.value;
            setGst(event.target.value);
          }}
          placeholder="Enter GST...(optional)"
          style={{
            width: "58%",
            padding: "6px",
            borderRadius: "5px",
            border: "1px solid rgb(204 204 204)",
            outline: "none",
            boxShadow: " 1px 1px 2px rgba(0, 0, 0, 0.3)",
          }}
        />
        <br />
        {validation !== "" && validation !== "Invalid email" && (
          <span style={{ color: "red", fontWeight: "bold", fontSize: "small" }}>
            {validation} can't be empty!
          </span>
        )}
        {validation !== "" && validation === "Invalid email" && (
          <span style={{ color: "red", fontWeight: "bold", fontSize: "small" }}>
            {validation}!
          </span>
        )}
        <br />
        <Link
          to="#"
          onClick={(e) => handleSubmit(e)}
          className={style.nextButton}
          replace
        >
          Proceed
        </Link>
      </div>
    </div>
  );
}

export default Registration;
