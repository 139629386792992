import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const baseSlice = createSlice({
  name: "basedata",
  initialState: initialState,
  reducers: {
    add: (state, action) => {
      state.data = action.payload;
    },
    delete: (state, action) => {
      state.data = state.data.filter((c, i) => i !== action.payload.ind);
    },
    empty: (state, action) => {
      state.data = [];
    },
  },
});

export const baseReducer = baseSlice.reducer;

export const baseActions = baseSlice.actions;

export const baseSelector = (state) => state.baseReducer.data;
