import React, { useEffect, useState } from "react";
import style from "../ui/SendMessageBody.module.css";
import SendMessageTemplate from "./SendMessageTemplate.js";
import { useSelector } from "react-redux";
import { userSelector } from "../../../redux/reducers/userReducer.js";
import { campaignBaseApi } from "../../../core/apiServices/apiServices.js";

function SendMessageBody({ selectedBase, campaign, template }) {
  const [phoneNumbers, setPhoneNumers] = useState("");
  const [validation, setValidation] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [base, setBase] = useState("");
  const userRedux = useSelector(userSelector);

  useEffect(() => {
    setBase(selectedBase.baseName);
    setPhoneNumers(selectedBase.data);

    function setContactBase(data) {
      let lines = data.split("\n");

      let arr = lines
        .map((line) => {
          if (!line.trim()) return null;

          let values = line.split(",");

          let number = parseInt(values.shift().trim()).toString();
          if (number.length < 10) {
            setValidation(true);
            return null;
          } else {
            setValidation(false);
          }

          let obj = { number: number };
          values.forEach((value, index) => {
            obj[`${index + 1}`] = value.trim();
          });
          return obj;
        })
        .filter((obj) => obj !== null);
      setContactList(arr);
    }
    if (selectedBase.data !== "") setContactBase(selectedBase.data);
  }, [selectedBase]);

  function handleSaveBase(e) {
    e.preventDefault();
    if (base === "") {
      setValidation("Base");
      return;
    }
    setValidation("");

    const baseJson = {
      action: "create",
      baseName: base,
      BACID: userRedux.BMCID,
      PHID: userRedux.PHID,
      mobileNo: userRedux.MobileNo,
      data: phoneNumbers,
    };//rana

    fetch(campaignBaseApi, {
      method: "POST",
      body: JSON.stringify(baseJson),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((err) => console.log("Eror base:", err));
  }

  function handlePhoneNumber(event) {
    event.preventDefault();
    setPhoneNumers(event.target.value);

    let lines = event.target.value.split("\n");

    let arr = lines
      .map((line) => {
        if (!line.trim()) return null;

        let values = line.split(",");

        let number = parseInt(values.shift().trim()).toString();
        if (number.length < 10) {
          setValidation(true);
          return null;
        } else {
          setValidation(false);
        }

        let obj = { number: number };
        values.forEach((value, index) => {
          obj[`${index + 1}`] = value.trim();
        });
        return obj;
      })
      .filter((obj) => obj !== null);
    setContactList(arr);
  }

  return (
    <div className={style.container}>
      <div className={style.numberContainer}>
        <div
          style={{
            marginBottom: "2vh",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <input
            type="text"
            id="baseInput"
            value={base}
            onChange={(e) => setBase(e.target.value)}
            placeholder="Enter base name..."
            className={style.baseInput}
          />
          <button className={style.button} onClick={(e) => handleSaveBase(e)}>
            Save Base
          </button>
        </div>
        <textarea
          className={style.phoneTextArea}
          value={phoneNumbers}
          onChange={(event) => handlePhoneNumber(event)}
          placeholder="Enter number and parameters here..."
        />
        {validation === "Base" && (
          <span
            style={{ color: "red", fontSize: "smaller", fontWeight: "bold" }}
          >
            Base Name is empty!
            <br />
          </span>
        )}
        {validation && (
          <span
            style={{ color: "red", fontSize: "smaller", fontWeight: "bold" }}
          >
            One number in the list is invalid!
            <br />
          </span>
        )}
        {contactList.length > 0 && (
          <span style={{ fontSize: "smaller" }}>
            <b>Contacts : {contactList.length}</b>
            <br />
          </span>
        )}
        <span style={{ fontSize: "small", fontWeight: "bold" }}>
          Ex. : 1643543211,param1,param2,..Enter
          <br />
          <span style={{ fontSize: "smaller" }}>
            Note : If want to use default variable for a particular param then
            write <b>default</b> at the place of it!
          </span>
        </span>
      </div>
      <div className={style.templateContainer}>
        <SendMessageTemplate
          campaign={campaign}
          data={template}
          contacts={contactList}
        />
      </div>
    </div>
  );
}

export default SendMessageBody;
