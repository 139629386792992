import React, { useState } from "react";
import style from "../ui/RazorPay.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Package from "../../../Package/usecases/Package.js";
import { userSelector } from "../../../../redux/reducers/userReducer.js";
import axios from "axios";
import { Alert } from "@mui/material";

function RazorPay() {
  const userRedux = useSelector(userSelector);

  const [amount, setAmount] = useState("");
  const [validation, setValidation] = useState("");

  const [alert, setAlert] = useState("");

  //   const navigate = useNavigate();

  async function handlePay(e) {
    e.preventDefault();

    if (amount === "") {
      <Alert severity="success">This is a success Alert.</Alert>;
      setValidation("Amount can't be empty");
      return;
    } else if (amount < 100) {
      setValidation("Amount can't less than 100 INR");
      return;
    }
    setValidation("");

    const payJson = { amount: amount };
    const response = await axios.post("http://localhost:3300/api/Pay", payJson);
    if (!response.data) {
      console.log("no response for order_id");
      setValidation(
        "Some issue in generating order currently,try again after few minutes"
      );
      return;
    }
    setValidation("");
    const data = response.data;
    var options = {
      key: data.key,
      amount: data.amount,
      currency: data.currency,
      name: "one17-crm",
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      order_id: data.order_id,
      //   callback_url: "https://eneqd3r9zrjok.x.pipedream.net/",
      handler: function (response) {
        alert("OrderID generated :", response.razorpay_order_id);
      },
      prefill: {
        name: userRedux.name,
        email: userRedux.email,
        contact: userRedux.MobileNo,
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "green",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert("Payment failed :", response.error.code);
      alert("Payment failed :", response.error.reason);
      //   setAlert("failed")
    });
    rzp1.open();
    setAlert("");
  }

  return (
    <div className={style.container}>
      {userRedux.package ? (
        <Package />
      ) : (
        <div className={style.childContainer}>
          <h1 className={style.loginHeader}>Payment</h1>
          <br />
          <label>Amount</label>
          <br />
          <input
            type="text"
            name="name"
            id="name"
            required
            value={amount}
            onChange={(event) => {
              setAmount(event.target.value);
            }}
            placeholder="Enter amount..."
            style={{
              width: "44%",
              padding: "10px 10px",
              borderRadius: "5px",
              border: "1px solid rgb(204 204 204)",
              outline: "none",
              boxShadow: " 1px 1px 2px rgba(0, 0, 0, 0.3)",
            }}
          />
          <br />
          {validation !== "" && (
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "small" }}
            >
              {validation}!
            </span>
          )}
          <br />
          <Link
            to="#"
            replace
            onClick={(e) => handlePay(e)}
            className={style.nextButton}
          >
            Proceed to Pay
          </Link>
          <br />
          {/* {alert === "success" && (
            <Alert severity="success" variant="filled">
              Payment successfull!
            </Alert>
          )} */}
        </div>
      )}
    </div>
  );
}

export default RazorPay;
