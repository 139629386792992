import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import Navbar from "./Components/Nav/Navbar.js";
import Login from "./Components/Login/usecases/Login.js";
import MessageTemplates from "./Components/MessageTemplates/usecases/MessageTemplates.js";
import CreateTemplate from "./Components/CreateTemplate/usecases/CreateTemplate.js";
import SendMessage from "./Components/SendMessage/usecases/SendMessage.js";
import Registration from "./Components/Login/usecases/Registration.js";
import Splash from "./Components/SplashScreen/Splash.js";
import NotFound from "./core/commonComponents/NotFound/NotFound.js";
import SuperAdmin from "./Components/SuperAdmin/SuperAdmin.js";
import Dashboard from "./Components/Dashboard/usecases/Dashboard.js";
import Admin from "./Components/admin/usecases/Admin.js";
import RazorPay from "./Components/Payments/RazorPay/usecases/RazorPay.js";
import Pending from "./Components/SplashScreen/Pending.js";

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Splash />,
      errorElement: <NotFound />,
    },
    {
      path: "/pending",
      element: <Pending />,
      errorElement: <NotFound />,
    },
    {
      path: "/login",
      element: <Login />,
      errorElement: <NotFound />,
    },
    {
      path: "/register",
      element: <Registration />,
      errorElement: <NotFound />,
    },
    {
      path: "/home",
      element: <Navbar />,
      errorElement: <NotFound />,
      children: [
        {
          index: true,
          element: <Dashboard />,
        },
        {
          path: "send",
          element: <SendMessage />,
        },
        {
          path: "templates",
          element: <MessageTemplates />,
        },
        {
          path: "create",
          element: <CreateTemplate />,
        },
        {
          path: "payment",
          element: <RazorPay />,
        },
        {
          path: "admin",
          element: <Admin />,
        },
        {
          path: "superadmin",
          element: <SuperAdmin />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
