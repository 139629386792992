import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {
    check: false,
    name: "",
    type: "",
    messageTemplateName: "",
    languages: [],
    media: {},
    buttons: {},
    quickReply: {},
  },
};

const categorySlice = createSlice({
  name: "categorydata",
  initialState: initialState,
  reducers: {
    add: (state, action) => {
      state.data = action.payload;
    },
    addHeader: (state, action) => {
      console.log("reducer header :", action.payload);
      state.data.header = action.payload;
    },
    addMedia: (state, action) => {
      console.log("reducer  media :", action.payload);
      state.data.media = action.payload;
    },
    deleteMedia: (state, action) => {
      state.data.media = false;
    },
    deleteUrl: (state, action) => {
      state.data.url = false;
    },
    addURL: (state, action) => {
      state.data.url = action.payload;
    },
    addBody: (state, action) => {
      state.data.body = action.payload;
    },
    addButtons: (state, action) => {
      state.data.buttons = action.payload;
    },
    addQuickReply: (state, action) => {
      state.data.quickReply = action.payload;
    },
    empty: (state, action) => {
      state.data = {
        check: false,
        name: "",
        type: "",
        messageTemplateName: "",
        languages: [],
        media: {},
        buttons: {},
        quickReply: {},
      };
    },
  },
});

export const categoryReducer = categorySlice.reducer;

export const categoryActions = categorySlice.actions;

export const categorySelector = (state) => state.categoryReducer.data;
