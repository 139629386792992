import React from "react";
import style from "../ui/SendMessageButtons.module.css";

function SendMessageButtons({ button }) {
  if (button.type === "URL") {
    return (
      <div className={style.urlContainer}>
        <span className={style.span}>URL : </span>
        <input className={style.textInput} value={button.text} readOnly />
        <input className={style.urlInput} value={button.url} readOnly />
      </div>
    );
  }
  if (button.type === "PHONE_NUMBER") {
    return (
      <div className={style.phoneContainer}>
        <span className={style.span}>Phone : </span>
        <input className={style.textphoneInput} value={button.text} readOnly />
        <input
          className={style.phoneNumberInput}
          value={button.phone_number}
          readOnly
        />
      </div>
    );
  }
  if (button.type === "QUICK_REPLY") {
    return (
      <div className={style.qrContainer}>
        <span className={style.span}>QUICK REPLY : </span>
        <input className={style.qrTextInput} value={button.text} readOnly />
      </div>
    );
  }
}

export default SendMessageButtons;
