import React, { useEffect, useState } from "react";
import { sendMessageUrl } from "../../../core/apiServices/apiServices.js";
import style from "../ui/SendMessageTemplate.module.css";
import SendMessageButtons from "./SendMessageButtons.js";
import { db, storage } from "../../../core/firebase/Firebase.js";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Link, useNavigate } from "react-router-dom";
import { campaignMasterModal } from "../modal/CampaignMasterModal.js";
import { set, ref as db_ref } from "firebase/database";
import { useSelector } from "react-redux";
import { userSelector } from "../../../redux/reducers/userReducer.js";

function SendMessageTemplate({ campaign, data, contacts }) {
  const [header, setHeader] = useState(null);
  const [body, setBody] = useState(null);
  const [footer, setFooter] = useState(null);
  const [buttons, setButtons] = useState(null);
  const [validation, setValidation] = useState("");
  const [campaignValidation, setCampaignValidation] = useState(false);
  const [contactLengthValidation, setContactLengthValidation] = useState(false);
  const [sendButton, setSentButton] = useState(false);
  const userRedux = useSelector(userSelector);

  //media
  const [file, setFile] = useState(null);
  let fileUploadFullPath;
  let downloadUrlVar;
  const [sizeValidation, setSizeValidation] = useState(false);
  const [mediaSelectValidation, setMediaSelectValidation] = useState(false);

  useEffect(() => {
    function setupHeader() {
      if (data.components) {
        const head = data.components.filter((ele) => ele.type === "HEADER");
        setHeader(head[0]);
        const body = data.components.filter((ele) => ele.type === "BODY");
        setBody(body[0]);
        const footer = data.components.filter((ele) => ele.type === "FOOTER");
        setFooter(footer[0]);
        const buttons = data.components.filter((ele) => ele.type === "BUTTONS");
        setButtons(buttons[0]);
      }
    }
    setupHeader();
  }, [data]);

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;
    const fileSizeInBytes = selectedFile.size;
    const fileSizeInKb = fileSizeInBytes / 1024;
    const fileSizeInMb = fileSizeInKb / 1024;

    if (fileSizeInMb > 2) {
      setSizeValidation(true);
      return;
    }
    setSizeValidation(false);
    setFile(event.target.files[0]);
  };

  const handleUpload = async (file) => {
    if (file) {
      const storageRef = ref(storage, "FileSend/" + file.name);

      const snapshot = await uploadBytes(storageRef, file);
      fileUploadFullPath = snapshot.metadata.fullPath;
    } else {
      console.error("No file selected");
    }
  };

  async function downloadUrlHelper(fullPath) {
    try {
      const starsRef = ref(storage, fullPath);
      const downloadURL = await getDownloadURL(starsRef);
      downloadUrlVar = downloadURL;
    } catch (err) {
      console.log("Error", err);
    }
  }

  const navigate = useNavigate();

  async function handleSend(e) {
    e.preventDefault();
    if (campaign === "") {
      setCampaignValidation(true);
      return;
    }
    setCampaignValidation(false);
    if (contacts.length <= 0) {
      setContactLengthValidation(true);
      return;
    }
    setContactLengthValidation(false);

    setSentButton(true);

    //file upload
    if (header && header.format !== "TEXT") {
      if (header.format !== "TEXT" && !file) {
        setMediaSelectValidation(true);
        setSentButton(false);
        return;
      }
      setMediaSelectValidation(false);

      await handleUpload(file);

      if (!fileUploadFullPath) {
        setMediaSelectValidation(true);
        setSentButton(false);
        return;
      }
      setMediaSelectValidation(false);

      await downloadUrlHelper(fileUploadFullPath);

      if (!downloadUrlVar) {
        setMediaSelectValidation(true);
        setSentButton(false);
        return;
      }
      setMediaSelectValidation(false);
    }

    //original
    let templateMessageModal = {
      Template: {
        messaging_product: "whatsapp",
        to: "",
        type: "template",
        template: {
          name: "",
          language: { code: "" },
          components: [],
        },
      },
      contactlist: [],
    };

    // header json
    if (header && header != null) {
      if (header.format === "TEXT" && header.example) {
        templateMessageModal.Template.template.components.push({
          type: "header",
          parameters: [
            {
              type: "text",
              text: header.example.header_text[0],
            },
          ],
        });
      }
      if (header && header.format === "IMAGE") {
        templateMessageModal.Template.template.components.push({
          type: "header",
          parameters: [
            {
              type: "image",
              image: {
                link: downloadUrlVar,
              },
            },
          ],
        });
      }
      if (header && header.format === "DOCUMENT") {
        templateMessageModal.Template.template.components.push({
          type: "header",
          parameters: [
            {
              type: "document",
              document: {
                link: downloadUrlVar,
                filename: file.name,
              },
            },
          ],
        });
      }
      if (header && header.format === "VIDEO") {
        templateMessageModal.Template.template.components.push({
          type: "header",
          parameters: [
            {
              type: "video",
              video: {
                link: downloadUrlVar,
              },
            },
          ],
        });
      }
    }

    // // body Json
    if (body && body.example) {
      let parameters = [];
      if (body.example.body_text[0][0]) {
        parameters.push({
          type: "text",
          text: body.example.body_text[0][0],
        });
      }
      if (body.example.body_text[0][1]) {
        parameters.push({
          type: "text",
          text: body.example.body_text[0][1],
        });
      }
      if (body.example.body_text[0][2]) {
        parameters.push({
          type: "text",
          text: body.example.body_text[0][2],
        });
      }
      templateMessageModal.Template.template.components.push({
        type: "body",
        parameters: parameters,
      });
    }
    templateMessageModal.Template.template.name = data.name;
    templateMessageModal.Template.template.language.code = data.language;
    templateMessageModal.contactlist = contacts;

    async function generateUniqueId() {
      var timestamp = Date.now();
      var random = Math.floor(Math.random() * 1000000);
      var uniqueId = timestamp.toString() + random.toString();
      return uniqueId;
    }

    function saveDBCampaignMaster(e, campId) {
      campaignMasterModal.campaignName = campaign;
      campaignMasterModal.templateName = data.name;
      campaignMasterModal.totalContacts = contacts.length;
      campaignMasterModal.userMobileNo = userRedux.MobileNo;
      campaignMasterModal.BACID = userRedux.BMCID;
      campaignMasterModal.PHID = userRedux.PHID;

      const now = new Date();
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var day = now.getDate();
      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;
      campaignMasterModal.date = year + "" + month + "" + day;

      var hours = now.getHours();
      var minutes = now.getMinutes();
      var seconds = now.getSeconds();
      var milliseconds = now.getMilliseconds();
      if (hours < 10) hours = "0" + hours;
      if (minutes < 10) minutes = "0" + minutes;
      if (seconds < 10) seconds = "0" + seconds;
      campaignMasterModal.time =
        hours + "" + minutes + "" + seconds + "" + milliseconds;

      console.log("campaing master :", campaignMasterModal);

      set(db_ref(db, `CampaignMaster/${campId}`), campaignMasterModal)
        .then(() => {
          console.log("saved Data to Campaign Master!");
          navigate("/home");
        })
        .catch((err) => {
          setSentButton(false);
          console.log("Error :", err);
        });
    }

    //fetch for send
    async function sendMessages(e) {
      const campId = await generateUniqueId();
      templateMessageModal.campId = campId;
      templateMessageModal.userMobileNo = userRedux.MobileNo;
      templateMessageModal.BACID = userRedux.BMCID;
      templateMessageModal.PHID = userRedux.PHID;

      console.log("final json :", JSON.stringify(templateMessageModal));
      try {
        fetch(sendMessageUrl, {
          method: "POST",
          body: JSON.stringify(templateMessageModal),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              setValidation("Error while sending,send again!");
              // alert("Some error happened,recheck values!");
              console.log("Error res :", data.error);
            }
            if (data.message === "Done") {
              setValidation("");
              console.log("Send message successfull! :", data);
              saveDBCampaignMaster(e, campId);
              setFile(null);
              // navigate("/home");
            }
          })
          .catch((err) => {
            setSentButton(false);
            setValidation("Error while sending,send again!");
            console.log("Error :", err);
          });
      } catch (err) {
        e.preventDefault();
        setSentButton(false);
        setValidation("Error while sending,send again!");
        console.log("Error :", err);
      }
    }
    await sendMessages(e);
  }

  return (
    <div className={style.container}>
      <div className={style.childContainer}>
        <div className={style.head}>
          <span className={style.spanHead}>
            <b>Template: {data.name}</b>
          </span>
          <span className={style.spanHead}>
            <b> Category : {data.category}</b>
          </span>
          <span className={style.spanHead}>
            <b> Status: {data.status}</b>
          </span>
        </div>
        {header && header !== null && header.format === "TEXT" && (
          <>
            <div className={style.header}>
              <div className={style.headerContainer}>
                <span className={style.spanHeader}>
                  <b>HEADER: </b>
                </span>
                <input
                  value={header.text}
                  className={style.headerInput}
                  readOnly
                />
              </div>
              {header.example && (
                <div className={style.headerVariableContainer}>
                  <span className={style.spanHeader}>
                    <b>{"{{1}}"}: </b>
                  </span>
                  <input
                    value={header.example.header_text[0]}
                    className={style.headerInput}
                    maxLength={25}
                    readOnly
                  />
                </div>
              )}
            </div>
          </>
        )}
        {header && header !== null && header.format === "IMAGE" && (
          <div className={style.header}>
            {/* <span className={style.spanHeader} style={{alignItems:'flex-start'}}>
              <b>IMAGE: </b>
            </span> */}
            {!file ? (
              <img
                src={header.example.header_handle[0]}
                alt="Preview"
                className={style.headerImage}
              />
            ) : (
              <img
                src={URL.createObjectURL(file)}
                alt="Selected"
                className={style.headerImage}
              />
            )}
            <input
              type="file"
              name="image"
              accept="image/jpeg, image/jpg, image/png"
              onChange={(e) => handleFileChange(e)}
              className={style.fileInput}
            />
          </div>
        )}
        {header && header !== null && header.format === "DOCUMENT" && (
          <div className={style.header}>
            {!file ? (
              <img
                src={header.example.header_handle[0]}
                alt="Document"
                className={style.headerImage}
              />
            ) : (
              <img
                src={URL.createObjectURL(file)}
                alt="Selected"
                className={style.headerImage}
              />
            )}
            <input
              type="file"
              name="document"
              accept=".pdf"
              onChange={(e) => handleFileChange(e)}
              className={style.fileInput}
            />
          </div>
        )}
        {header && header !== null && header.format === "VIDEO" && (
          <div className={style.header}>
            {!file ? (
              <img
                src={header.example.header_handle[0]}
                alt="Document"
                className={style.headerImage}
              />
            ) : (
              <img
                src={URL.createObjectURL(file)}
                alt="Selected"
                className={style.headerImage}
              />
            )}
            <input
              type="file"
              name="video"
              accept="video/mp4"
              onChange={(e) => handleFileChange(e)}
              className={style.fileInput}
            />
          </div>
        )}
        {sizeValidation && (
          <span
            style={{
              color: "red",
              fontSize: "small",
              fontWeight: "bold",
              marginBottom: "1vh",
            }}
          >
            File size should be less than 2mb!
          </span>
        )}
        {mediaSelectValidation && (
          <span
            style={{
              color: "red",
              fontSize: "small",
              fontWeight: "bold",
              marginBottom: "1vh",
            }}
          >
            Select file correctly!
          </span>
        )}
        {body && body !== null && (
          <div className={style.bodyContainer}>
            <div className={style.body}>
              <span className={style.spanBody}>
                <b>BODY: </b>
              </span>
              <textarea
                rows={8}
                cols={60}
                value={body.text}
                className={style.bodyInput}
                readOnly
              />
            </div>
            {body.example && (
              <div className={style.bodyVarContainer}>
                {body.example.body_text[0][0] && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className={style.spanBodyVariable}>
                      <b>{"{{1}}"}: </b>
                    </span>
                    <input
                      value={body.example.body_text[0][0]}
                      className={style.varbodyInput}
                      readOnly
                    />
                  </div>
                )}
                {body.example.body_text[0][1] && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className={style.spanBodyVariable}>
                      <b>{"{{2}}"}: </b>
                    </span>
                    <input
                      value={body.example.body_text[0][1]}
                      className={style.varbodyInput}
                      readOnly
                    />
                  </div>
                )}
                {body.example.body_text[0][2] && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className={style.spanBodyVariable}>
                      <b>{"{{3}}"}: </b>
                    </span>
                    <input
                      value={body.example.body_text[0][2]}
                      className={style.varbodyInput}
                      readOnly
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {footer && footer !== null && (
          <div className={style.footer}>
            <span className={style.spanFooter}>
              <b>FOOTER: </b>
            </span>
            <input value={footer.text} className={style.footerInput} readOnly />
          </div>
        )}
        {buttons && buttons !== null && (
          <div className={style.buttons}>
            {buttons.buttons.map((obj, index) => (
              <SendMessageButtons button={obj} key={index} />
            ))}
          </div>
        )}
        {body && body !== null && (
          <div className={style.saveContainer}>
            {campaignValidation ? (
              <span
                style={{ color: "red", fontSize: "small", fontWeight: "bold" }}
              >
                Campaign name is empty!
              </span>
            ) : (
              <span></span>
            )}

            {contactLengthValidation ? (
              <span
                style={{ color: "red", fontSize: "small", fontWeight: "bold" }}
              >
                Contacts are empty!
              </span>
            ) : (
              <span></span>
            )}
            {validation !== "" ? (
              <span
                style={{ color: "red", fontSize: "small", fontWeight: "bold" }}
              >
                {validation}
              </span>
            ) : (
              <span></span>
            )}
            {!sendButton && (
              <Link
                to="#"
                className={style.button}
                onClick={(e) => handleSend(e)}
              >
                Send
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default SendMessageTemplate;
