import React from "react";
import MarketingButtons from "../../../core/commonComponents/MarketingButtons/MarketingButtons.js";
import QuickReply from "../../../core/commonComponents/QuickReply/QuickReply.js";
import style from "../ui/MessageFormButtons.module.css";

function MessageFormButtons() {
  return (
    <div className={style.container}>
      <span>
        <b>Body</b> <br />
        <span style={{ fontSize: "small", fontWeight: "500" }}>
          Create buttons that let customers respond to your message or take
          action.
        </span>
      </span>
      <br />
      <MarketingButtons />
      <br />
      <span>
        <b>Quick reply</b> <br />
      </span>
      <br />
      <QuickReply />
    </div>
  );
}

export default MessageFormButtons;
