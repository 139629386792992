import React from "react";
import style from "../ui/CategoryCheckBox.module.css";

function CategoryCheckBox({ category, handleCheckBox }) {
  function handleChange(e) {
    const isChecked = e.target.checked;
    const thisValue = e.target.value;
    if (isChecked)
      handleCheckBox({
        check: isChecked,
        name: category.name,
        type: thisValue,
      });
    else handleCheckBox({ check: isChecked, name: "", type: "" });
  }

  return (
    <div>
      <div className={style.categoryDialogueBox}>
        <div className={style.checkbox}>
          <label>
            <input
              type="checkbox"
              value={category.one.title}
              onChange={handleChange}
            />
          </label>
        </div>
        <div className={style.checkboxTitle}>
          <span>
            <b>{category.one.title}</b>
            <br />
            {category.one.desc}
          </span>
        </div>
      </div>
      <div className={style.categoryDialogueBox}>
        <div className={style.checkbox}>
          <label>
            <input
              type="checkbox"
              value={category.two.title}
              onChange={handleChange}
              disabled
            />
          </label>
        </div>
        <div className={style.checkboxTitle}>
          <span>
            <b>{category.two.title}</b> <i>(Coming soon)</i> <br />
            {category.two.desc}
          </span>
        </div>
      </div>
      <div className={style.categoryDialogueBox}>
        <div className={style.checkbox}>
          <label>
            <input
              type="checkbox"
              value={category.three.title}
              onChange={handleChange}
              disabled
            />
          </label>
        </div>
        <div className={style.checkboxTitle}>
          <span>
            <b>{category.three.title}</b> <i>(Coming soon)</i>
            <br />
            {category.three.desc}
          </span>
        </div>
      </div>
    </div>
  );
}

export default CategoryCheckBox;
