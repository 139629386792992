import React, { useEffect, useState } from "react";

function isValidUrl(url) {
  var urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  return urlRegex.test(url);
}

function MarketingButtonType({ name, handleButtonLimit, handleForm }) {
  const [type, setType] = useState("URL");
  const [limitValidation, setLimitValidation] = useState(false);
  const [validationName, setValidationName] = useState("");

  const handleDropdownChange = (event) => {
    const res = handleButtonLimit(type, event.target.value);
    if (res) {
      setValidationName(event.target.value);
      setLimitValidation(true);
      return;
    }
    setLimitValidation(false);
    setType(event.target.value);
  };

  const [isReadOnly, setReadOnly] = useState(true);

  const handleCheckboxChange = () => {
    handleForm(name, null);
    setReadOnly(!isReadOnly); // Toggle readOnly state
  };

  // phone number
  const [selectedCountryCode, setSelectedCountryCode] = useState("+1");

  // Function to handle dropdown change
  const handleCountryDropdownChange = (event) => {
    setSelectedCountryCode(event.target.value);
  };

  const [buttonText, setButtonText] = useState("");
  const [url, setUrl] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emptyValidation, setEmptyValidation] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleInputChange = (event) => {
    const url = event.target.value;
    setUrl(url);
    setIsValid(isValidUrl(url));
  };

  useEffect(() => {
    if (limitValidation) return;
    if (isReadOnly) {
      handleForm(name, null);
      return;
    }
    let data;
    if (type === "URL") {
      if (buttonText === "" || url === "") {
        setEmptyValidation("Button text or URL");
        handleForm(name, null);
        return;
      }
      data = {
        type: type,
        value: buttonText,
        url: url,
      };
    } else if (type === "PHONE_NUMBER") {
      if (buttonText === "" || phoneNumber === "") {
        setEmptyValidation("Button text or Phone number");
        handleForm(name, null);
        return;
      }
      data = {
        type: type,
        value: buttonText,
        countryCode: selectedCountryCode,
        phoneNumber: phoneNumber,
      };
    }
    // else if (type === "Call on Whatsapp") {
    //   if (buttonText === "") {
    //     setEmptyValidation("Button text");
    //     handleForm(name, null);
    //     return;
    //   }
    //   data = {
    //     type: type,
    //     value: buttonText,
    //   };
    // }
    setEmptyValidation("");
    if (!isReadOnly) handleForm(name, data);
  }, [url, type, buttonText, isReadOnly, phoneNumber, selectedCountryCode]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: "10px",
          width: "98%",
        }}
      >
        <div style={{ width: "6%" }}>
          <input type="checkbox" onChange={handleCheckboxChange} />
        </div>
        {type === "URL" && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "96%",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", width: "18%" }}
            >
              <label for="buttontext" style={{ fontSize: "small" }}>
                Type of Action
              </label>
              <select
                value={type}
                onChange={handleDropdownChange}
                disabled={isReadOnly}
                style={{
                  width: "100%",
                  padding: "6px",
                  borderRadius: "5px",
                  border: "1px solid rgb(204, 204, 204)",
                  outline: "none",
                }}
              >
                <option value="URL" selected>
                  Visit Website
                </option>
                <option value="PHONE_NUMBER">Call Phone Number</option>
                {/* <option value="Call on Whatsapp">Call on Whatsapp</option> */}
                <option value="Copy Offer Code" disabled>
                  Copy Offer Code (coming soon )
                </option>
              </select>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "28%" }}
            >
              <label for="buttontext" style={{ fontSize: "small" }}>
                Button text
              </label>
              <input
                type="text"
                name="buttontext"
                readOnly={isReadOnly}
                value={buttonText}
                onChange={(event) => setButtonText(event.target.value)}
                style={{
                  width: "100%",
                  padding: "6px",
                  borderRadius: "5px",
                  border: "1px solid rgb(204, 204, 204)",
                  outline: "none",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "48%",
              }}
            >
              <label for="url" style={{ fontSize: "small" }}>
                Website URL
              </label>
              <input
                type="url"
                name="url"
                id="url"
                value={url}
                onChange={(e) => handleInputChange(e)}
                placeholder="https://example.com"
                pattern="https://.*"
                required
                readOnly={isReadOnly}
                style={{
                  width: "100%",
                  padding: "6px",
                  borderRadius: "5px",
                  border: "1px solid rgb(204, 204, 204)",
                  outline: "none",
                }}
              />
            </div>
          </div>
        )}
        {type === "PHONE_NUMBER" && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "96%",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", width: "18%" }}
            >
              <label for="buttontext" style={{ fontSize: "small" }}>
                Type of Action
              </label>
              <select
                value={type}
                onChange={handleDropdownChange}
                disabled={isReadOnly}
                style={{
                  width: "100%",
                  padding: "6px",
                  borderRadius: "5px",
                  border: "1px solid rgb(204, 204, 204)",
                  outline: "none",
                }}
              >
                <option value="URL" selected>
                  Visit Website
                </option>
                <option value="PHONE_NUMBER">Call Phone Number</option>
                {/* <option value="Call on Whatsapp">Call on Whatsapp</option> */}
                <option value="Copy Offer Code" disabled>
                  Copy Offer Code (coming soon )
                </option>
              </select>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "28%" }}
            >
              <label for="buttontext" style={{ fontSize: "small" }}>
                Button text
              </label>
              <input
                type="text"
                name="buttontext"
                readOnly={isReadOnly}
                value={buttonText}
                onChange={(event) => setButtonText(event.target.value)}
                style={{
                  width: "100%",
                  padding: "6px",
                  borderRadius: "5px",
                  border: "1px solid rgb(204, 204, 204)",
                  outline: "none",
                }}
              />
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "13%" }}
            >
              <label for="buttontext" style={{ fontSize: "small" }}>
                Country
              </label>
              <select
                value={selectedCountryCode}
                onChange={handleCountryDropdownChange}
                disabled={isReadOnly}
                style={{
                  width: "100%",
                  padding: "6px",
                  borderRadius: "5px",
                  border: "1px solid rgb(204, 204, 204)",
                  outline: "none",
                }}
              >
                <option value="+1">+1 U.S.</option>
                <option value="+44">+44 UK</option>
                <option value="+91">+91 India</option>
              </select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "32%",
              }}
            >
              <label for="phone" style={{ fontSize: "small" }}>
                Phone Number
              </label>
              <input
                type="text"
                name="phone"
                id="phone"
                maxLength={20}
                pattern="[0-9]*"
                required
                value={phoneNumber}
                onChange={(event) => setPhoneNumber(event.target.value)}
                readOnly={isReadOnly}
                style={{
                  width: "100%",
                  padding: "6px",
                  borderRadius: "5px",
                  border: "1px solid rgb(204, 204, 204)",
                  outline: "none",
                }}
              />
            </div>
          </div>
        )}
        {type === "Call on Whatsapp" && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              width: "96%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "3%",
                width: "18%",
              }}
            >
              <label for="buttontext" style={{ fontSize: "small" }}>
                Type of Action
              </label>
              <select
                value={type}
                onChange={handleDropdownChange}
                disabled={isReadOnly}
                style={{
                  width: "100%",
                  padding: "6px",
                  borderRadius: "5px",
                  border: "1px solid rgb(204, 204, 204)",
                  outline: "none",
                }}
              >
                <option value="URL" selected>
                  Visit Website
                </option>
                <option value="PHONE_NUMBER">Call Phone Number</option>
                {/* <option value="Call on Whatsapp">Call on Whatsapp</option> */}
                <option value="Copy Offer Code" disabled>
                  Copy Offer Code (coming soon )
                </option>
              </select>
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", width: "28%" }}
            >
              <label for="buttontext" style={{ fontSize: "small" }}>
                Button text
              </label>
              <input
                type="text"
                name="buttontext"
                readOnly={isReadOnly}
                value={buttonText}
                onChange={(event) => setButtonText(event.target.value)}
                style={{
                  width: "100%",
                  padding: "6px",
                  borderRadius: "5px",
                  border: "1px solid rgb(204, 204, 204)",
                  outline: "none",
                }}
              />
            </div>
          </div>
        )}
      </div>
      {!isValid && emptyValidation === "" && (
        <span
          style={{
            fontSize: "small",
            fontWeight: "bold",
            color: "red",
            padding: "10px",
          }}
        >
          Invalid URL!
          <br />
        </span>
      )}
      {limitValidation && (
        <span
          style={{
            fontSize: "small",
            fontWeight: "bold",
            color: "red",
            padding: "10px",
          }}
        >
          {validationName} can't be selected twice! <br />
        </span>
      )}
      {!limitValidation && emptyValidation !== "" && (
        <span
          style={{
            fontSize: "small",
            fontWeight: "bold",
            color: "red",
            padding: "10px",
          }}
        >
          {emptyValidation} can't be empty!
        </span>
      )}
    </>
  );
}

export default MarketingButtonType;
