import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import style from "../ui/MessageForm.module.css";
import MessageFormHeader from "./MessageFormHeader.js";
import MessageFormBody from "./MessageFormBody.js";
import MessageFormButtons from "./MessageFormButtons.js";
import { categorySelector } from "../../../redux/reducers/categoryReducer.js";
import { createTemplateUrl } from "../../../core/apiServices/apiServices.js";
import { userSelector } from "../../../redux/reducers/userReducer.js";


function MessageForm() {
  const [validation, setValidation] = useState("");
  const [sendButton, setSendButton] = useState(true);
  const reduxdata = useSelector(categorySelector);
  const userRedux = useSelector(userSelector);
  // const userData = useSelector(userSelector);
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    console.log(JSON.stringify(reduxdata));
    if (
      reduxdata.header &&
      reduxdata.header.headerType === "Text" &&
      reduxdata.header.headervariable.present === true &&
      reduxdata.header.headervariable.value === ""
    ) {
      setValidation("Header variable");
      return;
    }
    setValidation("");
    if (
      reduxdata.header &&
      reduxdata.header.headerType === "Media" &&
      !reduxdata.media
    ) {
      setValidation("Media");
      return;
    }
    setValidation("");
    if (
      reduxdata.header &&
      reduxdata.header.headerType === "Media" &&
      reduxdata.url === ""
    ) {
      setValidation("Media");
      return;
    }
    setValidation("");
    if (reduxdata.body === null) {
      setValidation("Body");
      return;
    }
    setValidation("");

    setSendButton(false);

    // async function setTemplates() {
    //   const tempBody = {
    //     action: "setTemplates",
    //     MobileNo: userData.MobileNo,
    //     Templates: [...userData.Templates, reduxdata.messageTemplateName],
    //   };
    //   axios
    //     .post(userProfileUrl, tempBody)
    //     .then((res) => {
    //       console.log("Templates received data:", res.data);
    //       dispatch(
    //         userActions.addTemplate([
    //           ...userData.Templates,
    //           reduxdata.messageTemplateName,
    //         ])
    //       );
    //       setValidation("");
    //     })
    //     .catch((err) => {
    //       console.log("Error :", err);
    //     });
    // }

    async function fetchReduxData() {
      console.log("final json :", reduxdata);
      const baseJson = {
        reduxdata : reduxdata,
        BACID: userRedux.BMCID,
        PHID: userRedux.PHID,
        mobileNo: userRedux.MobileNo,
      };//rana
      
      try {
        console.log("now fetch");
        fetch(createTemplateUrl, {
          method: "POST",
          // body: JSON.stringify(reduxdata),//rana
          body: JSON.stringify(baseJson),//rana
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            setValidation("");
            // setTemplates();
            navigate("/home/templates");
          })
          .catch((err) => {
            setValidation("Submit");
            setSendButton(true);
            console.log("Error :", err);
          });
      } catch (err) {
        setValidation("Submit");
        setSendButton(true);
        console.log("Error :", err);
      }
    }
    fetchReduxData();
  }

  return (
    <div className={style.container}>
      <div className={style.childContainer}>
        <MessageFormHeader />
        <MessageFormBody />
        <MessageFormButtons />
        <div className={style.submitContainer}>
          {validation === "File" ? (
            <span
              style={{
                marginLeft: "1vw",
                fontSize: "normal",
                fontWeight: "600",
                color: "red",
              }}
            >
              Media {validation} not correct, make sure file size is less than
              2mb!
            </span>
          ) : (
            <>
              {validation !== "" ? (
                <span
                  style={{
                    marginLeft: "1vw",
                    fontSize: "normal",
                    fontWeight: "600",
                    color: "red",
                  }}
                >
                  {validation} not correct, make sure some field is not empty!
                </span>
              ) : (
                <span></span>
              )}
            </>
          )}
          {sendButton && (
            <Link
              to="#"
              onClick={(e) => handleSubmit(e)} //rana - template create submit
              className={style.nextButton}
            >
              Submit
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default MessageForm;
