import React, { useState } from "react";
import style from "../ui/CategorySelector.module.css";
import CategoryCheckBox from "./CategoryCheckBox.js";
import NameAndLanguageTemplate from "./NameAndLanguageTemplate.js";

function CategorySelector({ handleCategoryForm }) {
  const [marketing, setMarketing] = useState(false);
  const [utility, setUtility] = useState(false);

  function onClickMarketing() {
    setMarketing(!marketing);
    setUtility(false);
    setTemplate({
      check: false,
      name: "",
      type: "",
    });
  }

  function onClickUtility() {
    setMarketing(false);
    setUtility(!utility);
    setTemplate({
      check: false,
      name: "",
      type: "",
    });
  }

  const [template, setTemplate] = useState({
    check: false,
    name: "",
    type: "",
  });
  function handleCheckBox(val) {
    setTemplate(val);
  }

  const [categoryValidation, setCategoryValidation] = useState(true);
  function handleCategoryValidation(v) {
    setCategoryValidation(v);
  }

  return (
    <div className={style.container}>
      <div className={style.category}>
        <p>
          <b>Category</b>
          <br />
          Choose a category that describes your message template.
        </p>
        <div className={style.marketingContainer}>
          <div
            className={style.marketingChildContainer}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "rgb(255 249 240)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "white";
            }}
          >
            <div onClick={() => onClickMarketing()}>
              <div>{/* logo */}</div>
              <div>
                <span>
                  <b>Marketing</b>
                  <br />
                  Promotion or information about your business, products or
                  services.Or any message that isn't utility or authentication
                </span>
              </div>
            </div>
            {marketing ? (
              <CategoryCheckBox
                category={categoryType[0]}
                handleCheckBox={handleCheckBox}
              />
            ) : (
              <></>
            )}
          </div>
          <div
            className={style.marketingChildContainer}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "rgb(255 249 240)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "white";
            }}
          >
            <div onClick={() => onClickUtility()}>
              <div>{/* logo */}</div>
              <div>
                <span>
                  <b>Utility</b>
                  <br />
                  Messages about specific transaction,account,order or customer
                  request.
                </span>
              </div>
            </div>
            {utility ? (
              <CategoryCheckBox
                category={categoryType[1]}
                handleCheckBox={handleCheckBox}
              />
            ) : (
              <></>
            )}
          </div>
          <div
            className={style.marketingChildContainer}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = "rgb(255 249 240)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "white";
            }}
          >
            <div>
              <div>{/* logo */}</div>
              <div>
                <span>
                  <b>Authentication</b> <i>(Coming soon)</i>
                  <br />
                  One-time passwords that your customers use to authenticate a
                  transaction to login.
                </span>
              </div>
            </div>
          </div>
        </div>
        {categoryValidation ? (
          <></>
        ) : (
          <span
            style={{
              color: "red",
              textAlign: "left",
              width: "80%",
              fontSize: "small",
              padding: "10px 20px",
            }}
          >
            Select one category to proceed!
          </span>
        )}
      </div>
      <div className={style.nameLanguageContainer}>
        <NameAndLanguageTemplate
          template={template}
          handleCategoryValidation={handleCategoryValidation}
          handleCategoryForm={handleCategoryForm}
        />
      </div>
    </div>
  );
}

const categoryType = [
  {
    name: "Marketing",
    one: {
      title: "Custom",
      desc: "Send promotional offers,announcements and more to increase awareness and engagement.",
    },
    two: {
      title: "Form",
      desc: "Send a custom form for lead generation,registration or re-engagement.",
    },
    three: {
      title: "Product Messages",
      desc: "Send messages about your entire catologue or multiple products form it.",
    },
  },
  {
    name: "Utility",
    one: {
      title: "Custom",
      desc: "Create your own messages.",
    },
    two: {
      title: "Order details message",
      desc: "Send messages with order details and payment options.",
    },
    three: {
      title: "Order status message",
      desc: "Send messages with order status.",
    },
  },
];

export default CategorySelector;
