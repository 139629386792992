import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {
    auth: false,
    rights: "viewer",
    action: "create",
    MobileNo: "",
    email: "",
    name: "",
    BusinessName: "",
    accessToken: "xyz123",
    BMCID: "abc123",
    PHID: "def456",
    businessmobileno: "",
    GSTNo: "",
    usageLimit: 0,
    Templates: [],
  },
};

const userSlice = createSlice({
  name: "userdata",
  initialState: initialState,
  reducers: {
    add: (state, action) => {
      state.user = action.payload;
    },
    onlyAuth: (state, action) => {
      state.user.auth = true;
    },
    addPhone: (state, action) => {
      state.user.MobileNo = action.payload;
    },
    addAuth: (state, action) => {
      state.user.auth = true;
      state.user.MobileNo = action.payload;
    },
    addRegister: (state, action) => {
      state.user.rights = "viewer";
      state.user.auth = true;
      state.user.name = action.payload.name;
      state.user.email = action.payload.email;
      state.user.BusinessName = action.payload.BusinessName;
      state.user.businessmobileno = action.payload.businessmobileno;
      state.user.GSTNo = action.payload.GSTNo;
    },
    addTemplate: (state, action) => {
      state.user.Templates.push(action.payload);
    },
    delete: (state, action) => {
      state.user = action.payload;
    },
    empty: (state, action) => {
      state.user = {
        auth: false,
        rights: "viewer",
        action: "create",
        MobileNo: "",
        email: "",
        name: "",
        BusinessName: "",
        accessToken: "NA",
        BMCID: "NA",
        PHID: "NA",
        businessmobileno: "",
        GSTNo: "",
        usageLimit: 0,
        Templates: [],
      };
    },
  },
});

export const userReducer = userSlice.reducer;

export const userActions = userSlice.actions;

export const userSelector = (state) => state.userReducer.user;
