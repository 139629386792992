import React, { useState } from "react";
import { useDispatch } from "react-redux";
import QuickReplyType from "./QuickReplyType.js";
import { categoryActions } from "../../../redux/reducers/categoryReducer.js";

function QuickReply() {
  const [qr1, setQr1] = useState(null);
  const [qr2, setQr2] = useState(null);
  const [qr3, setQr3] = useState(null);
  const [qr4, setQr4] = useState(null);

  const arr = ["b1", "b2", "b3", "b4"];

  const dispatch = useDispatch();

  function handleQuickReply(name, data) {
    if (name === "b1") {
      dispatch(
        categoryActions.addQuickReply({
          qr1: data,
          qr2: qr2,
          qr3: qr3,
          qr4: qr4,
        })
      );
      setQr1(data);
    } else if (name === "b2") {
      dispatch(
        categoryActions.addQuickReply({
          qr1: qr1,
          qr2: data,
          qr3: qr3,
          qr4: qr4,
        })
      );
      setQr2(data);
    } else if (name === "b3") {
      dispatch(
        categoryActions.addQuickReply({
          qr1: qr1,
          qr2: qr2,
          qr3: data,
          qr4: qr4,
        })
      );
      setQr3(data);
    } else if (name === "b4") {
      dispatch(
        categoryActions.addQuickReply({
          qr1: qr1,
          qr2: qr2,
          qr3: qr3,
          qr4: data,
        })
      );
      setQr4(data);
    }
  }

  return (
    <div>
      {arr.map((val, index) => (
        <QuickReplyType
          name={val}
          handleQuickReply={handleQuickReply}
          key={index}
        />
      ))}
    </div>
  );
}

export default QuickReply;
