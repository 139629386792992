import React, { useState } from "react";
import { useDispatch } from "react-redux";
import MarketingButtonType from "./MarketingButtonType.js";
import { categoryActions } from "../../../redux/reducers/categoryReducer.js";

function MarketingButtons() {
  const [btn1, setBtn1] = useState(null);
  const [btn2, setBtn2] = useState(null);
  const [buttons, setButtons] = useState({
    "Visit Website": 2,
    "Call Phone Number": 1,
    // "Call on Whatsapp": 1,
  });
  const arr = [{ name: "button1" }, { name: "button2" }];

  function handleButtonLimit(type1, type2) {
    if (buttons[type2] <= 0) {
      return true;
    }
    const data = buttons;
    data[type1] += 1;
    data[type2] -= 1;
    setButtons(data);
    return false;
  }

  const dispatch = useDispatch();

  function handleForm(name, data) {
    if (name === "button1") {
      dispatch(categoryActions.addButtons({ btn1: data, btn2: btn2 }));
      setBtn1(data);
    }
    if (name === "button2") {
      dispatch(categoryActions.addButtons({ btn1: btn1, btn2: data }));
      setBtn2(data);
    }
  }

  return (
    <div>
      {arr.map((val, index) => (
        <MarketingButtonType
          name={val.name}
          handleButtonLimit={handleButtonLimit}
          handleForm={handleForm}
          key={index}
        />
      ))}
    </div>
  );
}

export default MarketingButtons;
