import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { categoryActions } from "../../../redux/reducers/categoryReducer.js";
import style from "../ui/Media.module.css";
import { storage } from "../../../core/firebase/Firebase.js";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

function Media() {
  // const [downloadUrl, setDownloadUrl] = useState("");
  const [mediaType, setMediaType] = useState("IMAGE");
  const [sizeValidation, setSizeValidation] = useState(false);
  // const [fileDetails, setFileDetails] = useState(null);

  const dispatch = useDispatch();

  function downloadUrlHelper(fullPath) {
    const starsRef = ref(storage, fullPath);
    getDownloadURL(starsRef)
      .then((downloadURL) => {
        dispatch(categoryActions.addURL(downloadURL));
        // setDownloadUrl(downloadURL);
        console.log(downloadURL);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleUpload = (file) => {
    if (file) {
      const storageRef = ref(storage, "File/" + file.name);

      uploadBytes(storageRef, file)
        .then((snapshot) => {
          dispatch(categoryActions.addHeader({ headerType: "Media" }));
          dispatch(
            categoryActions.addMedia({
              type: snapshot.metadata.contentType,
              fullpath: snapshot.metadata.fullPath,
              size: snapshot.metadata.size,
              name: snapshot.metadata.name,
              mediatype: mediaType,
            })
          );
          downloadUrlHelper(snapshot.metadata.fullPath);
          console.log("Uploaded a blob or file!", snapshot);
        })
        .catch((err) => {
          console.log("Error:", err);
        });
    } else {
      console.error("No file selected");
    }
  };

  const handleFileChange = async (event) => {
    dispatch(categoryActions.addURL(""));
    const file = event.target.files[0];
    if (!file) return;
    const fileSizeInBytes = file.size;
    const fileSizeInKb = fileSizeInBytes / 1024;
    const fileSizeInMb = fileSizeInKb / 1024;
    // const fileType = file.type;

    if (fileSizeInMb > 2) {
      setSizeValidation(true);
      return;
    } else {
      setSizeValidation(false);
    }
    handleUpload(event.target.files[0]);
  };

  return (
    <>
      <div className={style.fileInputContainer}>
        <div className={style.boxContainer}>
          <div
            className={`${style.box1} ${
              mediaType === "IMAGE" ? style.active : ""
            }`}
            onClick={() => setMediaType("IMAGE")}
          >
            <p>Image</p>
          </div>
          <div
            className={`${style.box1} ${
              mediaType === "DOCUMENT" ? style.active : ""
            }`}
            onClick={() => setMediaType("DOCUMENT")}
          >
            <p>Document</p>
          </div>
          <div
            className={`${style.box1} ${
              mediaType === "VIDEO" ? style.active : ""
            }`}
            onClick={() => setMediaType("VIDEO")}
          >
            <p>Video</p>
          </div>
        </div>
        {mediaType === "IMAGE" && (
          <input
            type="file"
            name="image"
            accept="image/jpeg, image/jpg, image/png"
            onChange={handleFileChange}
            className={style.fileInput}
          />
        )}
        {mediaType === "DOCUMENT" && (
          <input
            type="file"
            name="document"
            accept=".pdf"
            onChange={handleFileChange}
            className={style.fileInput}
          />
        )}
        {mediaType === "VIDEO" && (
          <input
            type="file"
            name="video"
            accept="video/mp4"
            onChange={handleFileChange}
            className={style.fileInput}
          />
        )}
        <br />
      </div>
      {sizeValidation ? (
        <span style={{ fontSize: "small", color: "red" }}>
          File size should be less than 2 mb.
        </span>
      ) : (
        <></>
      )}
    </>
  );
}

export default Media;
