import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

const messageTemplateSlice = createSlice({
  name: "messageTemplatedata",
  initialState: initialState,
  reducers: {
    add: (state, action) => {
      state.data = action.payload.data;
    },
    delete: (state, action) => {
      console.log("reducer", action.payload.ind);
      state.data = state.data.filter((c, i) => i !== action.payload.ind);
    },
    empty: (state, action) => {
      state.data = {};
    },
  },
});

export const messageTemplateReducer = messageTemplateSlice.reducer;

export const messageTemplateActions = messageTemplateSlice.actions;

export const messageTemplateSelector = (state) =>
  state.messageTemplateReducer.data;
