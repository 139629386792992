import "./navbar.css";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { auth, db } from "../../core/firebase/Firebase.js";
import { useDispatch, useSelector } from "react-redux";
import { userActions, userSelector } from "../../redux/reducers/userReducer.js";
import { signOut } from "firebase/auth";
import { useEffect } from "react";
import { adminActions } from "../../redux/reducers/adminReducer.js";
import { superAdminActions } from "../../redux/reducers/superAdminReducer.js";
import { child, get, ref } from "firebase/database";
import { templateActions } from "../../redux/reducers/templateReducer.js";
import { categoryActions } from "../../redux/reducers/categoryReducer.js";
import { messageTemplateActions } from "../../redux/reducers/messageTemplateReducer.js";
import { baseActions } from "../../redux/reducers/baseReducer.js";

export default function Navbar() {
  const userRedux = useSelector(userSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function handleSignOut(e) {
    e.preventDefault();

    dispatch(userActions.empty());
    dispatch(baseActions.empty());
    dispatch(templateActions.empty());
    dispatch(categoryActions.empty());
    dispatch(adminActions.deleteAll());
    dispatch(messageTemplateActions.empty());
    dispatch(superAdminActions.deleteAll());

    signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        navigate("/login");
        console.log("Error while signing out :", error);
      });
  }

  useEffect(() => {
    if (!userRedux.auth || userRedux.rights === "viewer") {
      navigate("/login");
      return;
    }

    console.log("now checking if user admin/super-admin or not");

    const adminBMCid = userRedux.BMCID;
    const adminMobileNo = userRedux.MobileNo;

    function filterUsers(all) {
      Object.values(all).forEach((user) => {
        if (
          user.BMCID === adminBMCid &&
          user.MobileNo !== adminMobileNo &&
          user.rights !== "admin" &&
          user.BMCID !== "NA"
        ) {
          dispatch(adminActions.add(user));
        }
      });
    }

    function filterAdmins(all) {
      Object.values(all).forEach((user) => {
        if (
          (user.rights === "admin" || user.rights === "super-admin") &&
          user.MobileNo !== adminMobileNo &&
          user.BMCID !== "NA"
        ) {
          dispatch(superAdminActions.add(user));
        }
      });
    }

    if (userRedux.rights === "admin" || userRedux.rights === "super-admin") {
      const dbref = ref(db);
      get(child(dbref, `UserProfiles`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            if (userRedux.rights === "admin") filterUsers(snapshot.val());
            else if (userRedux.rights === "super-admin")
              filterAdmins(snapshot.val());
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  return (
    <div>
      <div className="navContainer">
        <Link className="navA" to="/home">
          <h1>One17</h1>
        </Link>
        <div className="navlist">
          <Link className="navA" to="/home">
            Dashboard
          </Link>
          <Link className="navA" to="/home/create">
            Create
          </Link>
          <Link className="navA" to="/home/templates">
            Template
          </Link>
          <Link className="navA" to="/home/send">
            Message
          </Link>
          {(userRedux.rights === "admin" ||
            userRedux.rights === "super-admin") && (
            <Link className="navA" to="/home/payment">
              Payment
            </Link>
          )}
          {userRedux.rights === "admin" && (
            <Link className="navA" to="/home/admin">
              Admin
            </Link>
          )}
          {userRedux.rights === "super-admin" && (
            <Link className="navA" to="/home/superadmin">
              Super Admin
            </Link>
          )}
          {userRedux.rights && (
            <Link
              to="#"
              className="navA"
              onClick={(e) => handleSignOut(e)}
              replace
            >
              SignOut
            </Link>
          )}
        </div>
      </div>
      <Outlet />
    </div>
  );
}
