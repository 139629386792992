import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
};

const superAdminSlice = createSlice({
  name: "usersdata",
  initialState: initialState,
  reducers: {
    add: (state, action) => {
      state.users.push(action.payload);
    },
    updateOne: (state, action) => {
      console.log("redux :", action.payload);
      state.users.map((user) => {
        if (user.MobileNo === action.payload.MobileNo) {
          user.rights = action.payload.rights;
        }
      });
    },
    deleteOne: (state, action) => {
      state.users.pop(action.payload);
    },
    deleteAll: (state, action) => {
      state.users = [];
    },
  },
});

export const superAdminReducer = superAdminSlice.reducer;

export const superAdminActions = superAdminSlice.actions;

export const superAdminSelector = (state) => state.superAdminReducer.users;
