export const createTemplateUrl =
  "https://us-central1-one17-technologies.cloudfunctions.net/waTemplateService";

export const sendMessageUrl =
  "https://us-central1-one17-technologies.cloudfunctions.net/WASender";

export const userProfileUrl =
  "https://us-central1-one17-technologies.cloudfunctions.net/UserApi/UserProfiles";

export const campaignBaseApi =
  "https://us-central1-one17-technologies.cloudfunctions.net/campaignBaseApi/CampaignBase";

// export const campaignMasterUrl =
//   "https://us-central1-one17-technologies.cloudfunctions.net/CampaignApis/CampaignMaster";

// export const userProfileUrl = "http:localhost:3100/UserProfiles";

// export const userProfileUrl = "https://jsonplaceholder.typicode.com/posts";
