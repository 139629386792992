import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MessageTemplateTable from "./MessageTemplateTable.js";
import "../ui/SearchBar.css";

function SearchBar({ templates, handleSearchTemplates }) {
  const [search, setSearch] = useState("");

  function handleSearch(e) {
    setSearch(e.target.value);
    if (e.target.value === "") {
      handleSearchTemplates("all");
    } else {
      if (templates) {
        const searchedTemplates = templates.filter((temp) => {
          console.log(temp);
          return (
            temp.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            temp.category.toLowerCase().includes(e.target.value.toLowerCase())
            // temp.name.substring(0, e.target.value.length).toLowerCase() ===
            //   e.target.value.toLowerCase() ||
            // temp.category.substring(0, e.target.value.length).toLowerCase() ===
            //   e.target.value.toLowerCase()
          );
        });

        handleSearchTemplates(searchedTemplates);
      }
    }
  }

  return (
    <div className="tableSeachContainer">
      <div className="searchButtonContainer">
        <div className="searchBarContainer">
          <div className="searchBarChildContainer">
            <ul className="ulContainer">
              <li>
                <input
                  className="searchBarInputBox"
                  name="search"
                  value={search}
                  onChange={(e) => handleSearch(e)}
                  placeholder="Search"
                />
              </li>
              {/* <li>
                <select
                  name="languages"
                  id="languages"
                  className="searchBoxDropDown"
                >
                  <option value="Language">Language</option>
                  <option value="English">English</option>
                  <option value="Hindi">Hindi</option>
                  <option value="Bengali">Bengali</option>
                </select>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="searchBarButtonsContainer">
          <Link to="/home/create" className="createTemplateLink">
            Create Template
          </Link>
        </div>
      </div>
      <MessageTemplateTable template={templates} />
    </div>
  );
}

export default SearchBar;
