import React from "react";
import { useNavigate } from "react-router-dom";
import style from "./Splash.module.css";

function Pending() {
  const navigate = useNavigate();
  return (
    <div className={style.container}>
      <h1 className={style.loginHeader} style={{ fontSize: "8em" }}>
        One17
      </h1>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <span className={style.loginHeader}>
          Approval Pending ,wait here till someone from the team approves your
          account... <br />
          <br /> or
        </span>
        <button
          type="button"
          className={style.loginBtn}
          onClick={() => navigate("/login")}
        >
          Login
        </button>
      </div>
    </div>
  );
}

export default Pending;
