import React, { useState } from "react";
import { useDispatch } from "react-redux";
import style from "../ui/CreateTemplate.module.css";
import CategorySelector from "./CategorySelector.js";
import Template from "../../CreateMediaTemplate/usecases/Template.js";
import { categoryActions } from "../../../redux/reducers/categoryReducer.js";

function CreateTemplate() {
  const [categoryForm, setCategoryForm] = useState({});

  const dispatch = useDispatch();

  function handleCategoryForm(obj) {
    setCategoryForm(obj);
    dispatch(categoryActions.add(obj));
  }

  return (
    <div>
      {categoryForm.check ? (
        <div className={style.templateContainer}>
          <Template categoryForm={categoryForm} />
        </div>
      ) : (
        <CategorySelector handleCategoryForm={handleCategoryForm} />
      )}
    </div>
  );
}

export default CreateTemplate;
