import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./reducers/userReducer.js";
import { categoryReducer } from "./reducers/categoryReducer.js";
import { templateReducer } from "./reducers/templateReducer.js";
import { messageTemplateReducer } from "./reducers/messageTemplateReducer.js";
import { adminReducer } from "./reducers/adminReducer.js";
import { superAdminReducer } from "./reducers/superAdminReducer.js";
import { baseReducer } from "./reducers/baseReducer.js";

export const store = configureStore({
  reducer: {
    userReducer,
    categoryReducer,
    templateReducer,
    messageTemplateReducer,
    baseReducer,
    adminReducer,
    superAdminReducer,
  },
});
