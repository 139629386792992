import React, { useState } from "react";
import style from "../ui/NameAndLanguageTemplate.module.css";

function NameAndLanguageTemplate({
  template,
  handleCategoryValidation,
  handleCategoryForm,
}) {
  const [name, setName] = useState("");
  const [emptyNameValidation, setEmptyNameValidation] = useState(true);

  // select languages

  const [emptyLanguageValidation, setEmptyLanguageValidation] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectChange = (event) => {
    const selectedOption = event.target.value;
    const selectedIndex = selectedOptions.indexOf(selectedOption);
    let newSelectedOptions = [...selectedOptions];

    if (selectedIndex === -1) {
      newSelectedOptions.push(selectedOption); // Add the option if it's not already selected
    } else {
      newSelectedOptions.splice(selectedIndex, 1); // Remove the option if it's already selected
    }

    setSelectedOptions(newSelectedOptions);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleDeleteOption = (option) => {
    const newSelectedOptions = selectedOptions.filter(
      (item) => item !== option
    );
    setSelectedOptions(newSelectedOptions);
  };

  // next button handler
  function handleNext() {
    if (template.check) {
      template.messageTemplateName = name.toLowerCase();
      template.languages = selectedOptions;
      handleCategoryValidation(template.check);
    } else {
      handleCategoryValidation(template.check);
      return;
    }
    if (template.messageTemplateName === "") {
      setEmptyNameValidation(false);
      return;
    } else {
      setEmptyNameValidation(true);
    }
    if (template.languages.length === 0) {
      setEmptyLanguageValidation(false);
      return;
    } else {
      setEmptyLanguageValidation(true);
    }
    handleCategoryForm(template);
    console.log("Success");
  }

  return (
    <div className={style.nameLanguagecontainer}>
      <div className={style.nameContainer}>
        <span>
          <b>Name</b>
          <br />
          Name your message template (can include only lowercase letters, _ and
          can only start with a letter )
        </span>
        <br />
        <input
          id="onName"
          type="text"
          className={style.nameInput}
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter message template name... "
          maxLength={512}
        />
        <br />
        {emptyNameValidation ? (
          <></>
        ) : (
          <span
            style={{
              color: "red",
              textAlign: "left",
              width: "100%",
              padding: "0px 8px",
              fontSize: "small",
            }}
          >
            Enter name to proceed!
          </span>
        )}
      </div>
      <div className={style.languagesContainer}>
        <span>
          <b>Languages</b>
          <br />
          Choose languages for your message template.You can delete or add more
          languages later
        </span>
        <br />
        <div
          style={{ position: "relative", fontFamily: "Arial, sans-serif" }}
          onMouseLeave={handleMouseLeave}
        >
          <div
            style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
          >
            {selectedOptions.map((option) => (
              <div
                key={option}
                style={{
                  backgroundColor: "#ffc150",
                  borderRadius: "4px",
                  padding: "4px 8px",
                  margin: "4px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span style={{ marginRight: "4px" }}>{option}</span>
                <span
                  style={{
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                  onClick={() => handleDeleteOption(option)}
                >
                  &#x2715;
                </span>
              </div>
            ))}
            <input
              id="onLanguageSelect"
              type="text"
              value={""}
              onClick={() => handleToggle()}
              placeholder="Select options..."
              style={{
                width: "100%",
                padding: "8px",
                fontSize: "16px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            />
          </div>
          {isOpen && (
            <select
              multiple
              value={selectedOptions}
              onChange={handleSelectChange}
              style={{
                position: "absolute",
                width: "100%",
                border: "1px solid #ccc",
                borderTop: "none",
                borderRadius: "0 0 4px 4px",
                backgroundColor: "#fff",
                padding: "4px 8px",
                fontSize: "18px",
                cursor: "pointer",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <option value="en_US">English U.S.</option>
              <option value="en">English</option>
              <option value="hi">Hindi</option>
              <option value="bn">Bangla</option>
            </select>
          )}
        </div>
        {emptyLanguageValidation ? (
          <></>
        ) : (
          <span
            style={{
              color: "red",
              textAlign: "left",
              width: "100%",
              fontSize: "small",
              padding: "10px 22px 0px 0px",
            }}
          >
            Select language to proceed!
          </span>
        )}
      </div>
      <div className={style.submitContainer}>
        <span>
          <b>New Message Template</b>
        </span>
        <br />
        <button className={style.nextButton} onClick={handleNext}>
          Next
        </button>
      </div>
    </div>
  );
}

export default NameAndLanguageTemplate;
